import {
    useCallback,
    useMemo,
    forwardRef,
    useImperativeHandle,
    useRef,
} from "react";

import {
    AudioPlayerHandle,
    AudioPlayerProps,
    extendAudioProps,
} from "./audio-player";

export const BasicAudioPlayer = forwardRef<AudioPlayerHandle, AudioPlayerProps>(
    (props, ref) => {
        const { audio } = props;
        const audioRef = useRef<HTMLAudioElement>(null);

        useImperativeHandle(ref, () => ({
            play: () => {
                audioRef?.current?.play();
            },

            pause: () => {
                audioRef?.current?.pause();
            },

            seek: (timecode: number) => {
                if (audioRef.current == null) {
                    return;
                }

                audioRef.current.currentTime = timecode;
            },
            muted: (muted: boolean) => {
                if (audioRef.current == null) {
                    return;
                }

                audioRef.current.muted = muted;
            },
            volume: (volume: number) => {
                if (audioRef.current == null) {
                    return;
                }

                audioRef.current.volume = volume;
            },
        }));

        const handleAudioLoaded = useCallback(() => {
            props?.onLoaded?.({
                duration: audioRef?.current?.duration ?? 0,
            });
        }, []);

        const eventAudioProps = useMemo(() => {
            return extendAudioProps(props);
        }, []);

        return (
            <audio
                ref={audioRef}
                src={audio.url}
                onLoadedMetadata={() => handleAudioLoaded()}
                onWaiting={props.onLoading}
                {...eventAudioProps}
            />
        );
    }
);
