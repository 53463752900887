import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";

import { stringify as queryStringify } from "qs";

import { customTextDisplayerFactory } from "@app/components/shaka/text-displayer";

import styles from "@app/assets/styles/video-player.module.scss";

import {
    AudioPlayerHandle,
    AudioPlayerProps,
    extendAudioProps,
} from "./audio-player";
import { buildWidevineNetFilter } from "../shaka/ezdrm";

declare const shaka: any;

export const ShakaAudioPlayer = forwardRef<AudioPlayerHandle, AudioPlayerProps>(
    (props, ref) => {
        const { audio, onError, onLoading } = props;
        const audioRef = useRef<HTMLAudioElement | null>(null);
        const shakaRef = useRef(null);
        const [isAudioNodeReady, setAudioNodeReady] = useState<boolean>(false);

        useImperativeHandle(ref, () => ({
            play: () => {
                audioRef?.current?.play();
            },

            pause: () => {
                audioRef?.current?.pause();
            },

            seek: (timecode: number) => {
                if (audioRef.current) {
                    audioRef.current.currentTime = timecode;
                }
            },
            muted: (muted: boolean) => {
                if (audioRef.current == null) {
                    return;
                }

                audioRef.current.muted = muted;
            },
            volume: (volume: number) => {
                if (audioRef.current == null) {
                    return;
                }

                audioRef.current.volume = volume;
            },
        }));

        // Error handler
        const handlePlayerError = useCallback((error: unknown) => {
            if (onError != null) {
                onError(error);
            }
        }, []);

        const initShaka = useCallback(async () => {
            try {
                // Install built-in polyfills to patch browser incompatibilities.
                shaka.polyfill.installAll();
                shaka.polyfill.PatchedMediaKeysApple.install();
                const FairPlayUtils = shaka.util.FairPlayUtils;

                // Check to see if the browser supports the basic APIs Shaka needs.
                if (!shaka.Player.isBrowserSupported()) {
                    throw new Error(
                        "Your browser is not compatible with the player."
                    );
                }
                const shakaPlayer = new shaka.Player(audioRef.current);
                shakaRef.current = shakaPlayer;
                shakaPlayer.addEventListener("error", handlePlayerError);

                // Configure player
                const playerConfig: any = {};
                const requestNetFilters: any[] = [];
                const responseNetFilters: any[] = [];

                if (audio?.drmConfig != null) {
                    if (audio.drmConfig.type == "widevine") {
                        playerConfig.drm = {
                            servers: {
                                "com.widevine.alpha": audio.drmConfig.serverUrl,
                            },
                        };
                    }
                    if (audio.drmConfig.type == "widevine") {
                        playerConfig.drm = {
                            servers: {
                                "com.widevine.alpha": audio.drmConfig.serverUrl,
                            },
                        };
                        const drmRequestNetFilter = await buildWidevineNetFilter(
                            shakaPlayer,
                            audio
                        );

                        if (drmRequestNetFilter != null) {
                            requestNetFilters.push(drmRequestNetFilter);
                        }
                    } else if (audio.drmConfig.type == "fairplay") {
                        playerConfig.drm = {
                            servers: {
                                "com.apple.fps.1_0": audio.drmConfig.serverUrl + "?"
                                    + queryStringify(audio.drmExtra ?? {}),
                            },
                            advanced: {
                                "com.apple.fps.1_0": {
                                    serverCertificateUri: audio.drmConfig.fairplayCertificateUrl
                                },
                            },
                            initDataTransform: FairPlayUtils.ezdrmInitDataTransform,
                        };
                        requestNetFilters.push(FairPlayUtils.ezdrmFairPlayRequest);
                        responseNetFilters.push(FairPlayUtils.commonFairPlayResponse);
                    }
                }

                // Everything looks good!
                shakaPlayer.configure(playerConfig);

                // Register network filters
                const nwe = shakaPlayer.getNetworkingEngine();
                requestNetFilters.forEach((netFilter: any) => {
                    nwe.registerRequestFilter(netFilter);
                });
                responseNetFilters.forEach((netFilter: any) => {
                    nwe.registerResponseFilter(netFilter);
                });

                // Load video manifest
                await shakaPlayer.load(audio.url);

                props?.onLoaded?.({
                    duration: audioRef.current
                        ? audioRef?.current?.duration
                        : 0,
                });
            } catch (error) {
                handlePlayerError(error);
            }
        }, []);

        // Used to initialize player
        const initAudioRef = useCallback((node: any) => {
            if (node == null) {
                return;
            }

            audioRef.current = node;
            setAudioNodeReady(true);
        }, []);

        // Mount/unmount player
        useEffect(() => {
            initShaka().then(() => {
                console.log("audio launched");
            });

            return () => {
                const removePlayer = async () => {
                    if (shakaRef.current) {
                        await (shakaRef.current as any).detach();
                    }
                };

                removePlayer().catch((error) => {
                    console.log(error);
                });
            };
        }, [isAudioNodeReady]);

        const eventAudioProps = useMemo(() => {
            return extendAudioProps(props);
        }, [props.onEnded, props.onTimeUpdate]);

        return (
                <audio
                    {...eventAudioProps}
                    ref={initAudioRef}
                    onWaiting={onLoading}
                />
        );
    }
);
