import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { Language } from "./utils/Language";
import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';
import translationSL from './locales/sl.json';

let defaultLanguage = Language.EN;

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  sl: {
    translation: translationSL
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: defaultLanguage,

    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;
