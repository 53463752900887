import { useEffect, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { Player } from "@components";
import { buildCssVariables } from "./utils/theme";

import { Base64 } from "js-base64";
import { useTranslation } from "react-i18next";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function updateDocumentCssVariables() {
    const rootStyle = document.documentElement.style;

    if (rootStyle != null) {
        const cssVariables = buildCssVariables();
        for (const cssVarName in cssVariables) {
            const cssVarValue = cssVariables[cssVarName];

            if (cssVarValue != null) {
                rootStyle.setProperty(cssVarName, cssVarValue.toString());
            }
        }
    }
}

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

function InjectStylePlayer(props: any) {
    const query = useQuery();
    const { i18n } = useTranslation();
    const cssEncoded = query.get("css");
    const rootClass = query.get("rootclass");
    const viewMode = query.get("viewmode");
    const controls = query.get("controls") ?? "";
    const drmExtraEncoded = query.get("drmextra");
    const languageCode = query.get("i18nlang");
    const playerTracksMode = query.get("i18ntrackmode");
    const overlayEncoded = query.get("overlay");
    const cssUrl = cssEncoded ? Base64.decode(cssEncoded) : undefined;
    const drmExtra = drmExtraEncoded
        ? JSON.parse(Base64.decode(drmExtraEncoded))
        : undefined;
    const overlay = overlayEncoded
        ? JSON.parse(Base64.decode(overlayEncoded))
        : undefined;

    if (languageCode && languageCode !== i18n.language) {
        i18n.changeLanguage(languageCode);
    }

    return (
        <HelmetProvider>
            <Helmet>
                <link rel="stylesheet" href={cssUrl} />
            </Helmet>
            <div className={rootClass ?? undefined}>
                <Player
                    viewMode={viewMode}
                    controls={controls}
                    drmExtra={drmExtra}
                    playerTracksMode={playerTracksMode}
                    overlay={overlay}
                />
            </div>
        </HelmetProvider>
    );
}

function App() {
    useEffect(() => {
        updateDocumentCssVariables();
    }, []);
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/embed/:mediaDistributionId"
                    element={
                        <QueryClientProvider client={queryClient}>
                            <InjectStylePlayer />
                        </QueryClientProvider>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
