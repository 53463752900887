import { useQuery } from "@tanstack/react-query";

import { useParams } from "react-router-dom";

import VideoPlayer from "./video";
import BookPlayer from "./book";
import AudioPlayer from "./audio";

import AudiobookPlayer from "./audiobook";

export const Player = (props: any) => {
    const { mediaDistributionId } = useParams();
    const mediaDistributionQuery = useQuery(["mediaDistribution"], async () => {
        const response = await fetch("/api/media-distribution/" + mediaDistributionId);
        const result = await response.json();
        return result;
    }, {
        refetchOnWindowFocus: false
    });

    const mediaDistribution = mediaDistributionQuery?.data;

    if (mediaDistribution?.playerType == null) {
        return <></>;
    }

    const { contentType, url, playerType, drm } = mediaDistribution;
    const mediaDistributionUrl = {
        url,
        contentType
    };

    if (playerType === "book") {
        return <BookPlayer mediaDistributionUrl={mediaDistributionUrl} {...props} />;
    } else if (playerType === "video") {
        return <VideoPlayer mediaDistributionUrl={mediaDistributionUrl} drmConfig={drm} {...props} />;
    } else if (playerType === "audio") {
        return <AudioPlayer mediaDistributionUrl={mediaDistributionUrl} drmConfig={drm} {...props} />
    } else if (playerType === "audiobook") {
        return <AudiobookPlayer mediaDistributionUrl={mediaDistributionUrl} {...props} />
    }

    return <></>;
};

export default Player;
