import { DrmProvider } from "@app/models/drm";

// import { config as variantConfig } from "variant";

// import * as drmToday from "./drmtoday";
// import * as drmVualto from "./vualto";
import * as ezDrm from "./ezdrm";
import { Video } from "../video/video-player";
import { DrmConfig } from "@app/models/player";

// declare const shaka: any;

export async function buildWidevineNetFilter(player: any, video: Video) {
    if (video?.drmConfig?.provider == "ezdrm") {
        return ezDrm.buildWidevineNetFilter(player, video);
    }

    return null;
}
