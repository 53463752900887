import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";

dayjs.extend(duration);
dayjs.extend(utc);

/**
 *
 * @param duration Duration in minutes
 */
export function minutesToDuration(duration: number) {
    const momentDuration = dayjs.duration(duration, "minutes");

    if (duration < 60) {
        return duration.toString().padStart(2, "0") + "min";
    }

    const formatedHours = momentDuration.hours();
    const formatedMinutes = momentDuration.minutes();
    return (
        formatedHours.toString().padStart(2, "0") +
        "h" +
        formatedMinutes.toString().padStart(2, "0")
    );
}

// export function secondsToDuration(totalSeconds: number, type: "hh:mm:ss" | "mm:ss" = "hh:mm:ss") {
export function secondsToDuration(totalSeconds: number, type: string) {
const hours = String(Math.floor(totalSeconds / 3600));
    if (type === "hh:mm:ss") {
        totalSeconds %= 3600;
    }
    const minutes = String(Math.floor(totalSeconds / 60));
    const seconds = String(Math.trunc(totalSeconds % 60));
    const duration = `${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
    if (hours !== "0" && type === "hh:mm:ss") {
        return `${hours}:${duration}`;
    }
    return duration;
}

/**
 *
 * @param duration Duration in ISO format
 */
export function formatISODuration(duration: string) {
    const momentDuration = dayjs.duration(duration);
    const totalMinutes = Math.floor(momentDuration.asMinutes());

    // FIXME: Should come from variant
    // variantConfig.settings.features.durationPad
    const useDurationPad = false;

    if (totalMinutes < 60) {
        if (useDurationPad) {
            return totalMinutes.toString().padStart(2, "0") + "min";
        } else {
            return totalMinutes.toString() + "min";
        }
    }

    const formatedHours = momentDuration.hours();
    const formatedMinutes = momentDuration.minutes();

    if (useDurationPad) {
        return (
            formatedHours.toString().padStart(2, "0") +
            "h" +
            formatedMinutes.toString().padStart(2, "0")
        );
    } else {
        return formatedHours.toString() + "h" + formatedMinutes.toString().padStart(2, "0");
    }
}

/**
 *
 * @param date date in ISO format
 */
export function formatISODate(date: string) {
    const momentDuration = dayjs.duration(date);
    return dayjs.utc(momentDuration.asMilliseconds()).format("DD/MM/YYYY");
}
