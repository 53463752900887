import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import sanitizeHtml from "sanitize-html";

import { PlayerState } from "./VideoPlayer";

import style from "@app/assets/styles/video-player.module.scss";
import { ProgressBar } from "../ProgressBar";
import { Oval } from "react-loader-spinner";

interface Props {
    children?: any;
    duration: number;
    PlayerControls: any;
    subtitles?: any[];
    currentSubtitleIndex: number;
    handleSubtitle: any;
    audios: any;
    currentAudioIndex: any;
    handleAudio: any;
    playerState: PlayerState;
    forceRender: any;
    handlePlayPauseButton: () => void;
    changeCurrentTime: (time: number) => void;
    timeForward: (value: number) => void;
    replay: (value: number) => void;
    progression: number;
    fullscreen: boolean;
    handleFullscreen: () => void;
    mouseActivity: boolean;
    handleMouseActivity: () => void;
    handleMuted: () => void;
    handleVolume: (value: number) => void;
    currentVolume: number;
    onClickVideo: () => void;
    onDoubleClickVideo: () => void;
    playerTracksMode?: string;
    overlay?: { textLines: string[]; html: string };
}

export default function PlayerLayout(props: Props) {
    const {
        children,
        changeCurrentTime,
        duration,
        subtitles,
        PlayerControls,
        currentSubtitleIndex,
        handleSubtitle,
        audios,
        currentAudioIndex,
        handleAudio,
        playerState,
        forceRender,
        mouseActivity,
        overlay,
    } = props;

    const [init, setInit] = useState<boolean>(false);
    const animation = useRef<NodeJS.Timeout>();
    const hideTime = 5000;
    const hideTimeout = useRef<NodeJS.Timeout>();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [keepVisible, setKeepVisible] = useState(false);

    useEffect(() => {
        if (!init) {
            hide();
            setInit(true);
        }
    }, [init]);

    useEffect(() => {
        if (playerState.lastFastMove && playerState.play) {
            playerState.hiding = false;
            hide();
        }
    }, [playerState.lastFastMove]);

    useEffect(() => {
        if (playerState.play && !playerState.loading) {
            hide();
        } else {
            clearTimeout(hideTimeout.current);
            playerState.hiding = false;
        }
        forceRender();
    }, [playerState.play, playerState.loading]);

    function hide() {
        if (hideTimeout.current) {
            clearTimeout(hideTimeout.current);
        }

        hideTimeout.current = setTimeout(
            () => (playerState.hiding = true),
            hideTime
        );
        forceRender();
    }

    const inIframe = useMemo(() => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }, []);

    const sendPostMessage = useCallback(
        (type: string, data?: any) => {
            if (inIframe) {
                window.parent.postMessage(
                    {
                        type,
                        data,
                    },
                    "*"
                );
            }
        },
        [inIframe]
    );

    useEffect(() => {
        return () => {
            if (animation.current) {
                clearTimeout(animation.current);
            }
        };
    }, []);

    const pausedAndNotFullscreen = !playerState.play && !props.fullscreen;

    const controlsVisible =
        keepVisible || settingsOpen || mouseActivity || pausedAndNotFullscreen;

    useEffect(() => {
        sendPostMessage("controls-visible", controlsVisible);
    }, [controlsVisible]);

    return (
        <div
            className={style.playerLayout}
            style={controlsVisible ? {} : { cursor: "none" }}
        >
            {children}
            <div
                className={style.overlay}
                onClick={props.onClickVideo}
                onDoubleClick={props.onDoubleClickVideo}
            >
                {playerState.loading && (
                    <div className={style.loader}>
                        <Oval
                            height={100}
                            width={100}
                            color="#FFFFFF"
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#FFFFFF"
                            strokeWidth={5}
                            strokeWidthSecondary={5}
                        />
                    </div>
                )}
                {controlsVisible && (
                    <>
                        {overlay && (
                            <div className={style.customOverlay}>
                                <div>
                                    {overlay.html && (
                                        <div
                                            className={style.htmlOverlay}
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeHtml(
                                                    overlay.html,
                                                    {
                                                        allowedTags:
                                                            sanitizeHtml.defaults.allowedTags.concat(
                                                                ["img"]
                                                            ),
                                                        allowedAttributes: {
                                                            "*": ["class"],
                                                            a: [
                                                                "href",
                                                                "name",
                                                                "target",
                                                            ],
                                                            img: [
                                                                "src",
                                                                "srcset",
                                                                "alt",
                                                                "title",
                                                                "width",
                                                                "height",
                                                                "loading",
                                                            ],
                                                        },
                                                    }
                                                ),
                                            }}
                                        />
                                    )}
                                    {overlay.textLines &&
                                        overlay.textLines.length > 0 && (
                                            <div className={style.textOverlay}>
                                                {overlay.textLines.map((textLine) => (
                                                    <p>{textLine}</p>
                                                ))}
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}

                        <div
                            className={style.bottomContainer}
                            onMouseEnter={() => setKeepVisible(true)}
                            onMouseLeave={() => setKeepVisible(false)}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <ProgressBar
                                playerState={playerState}
                                duration={duration}
                                changeCurrentTime={changeCurrentTime}
                                style={style}
                            />
                            <PlayerControls
                                handlePlayPauseButton={
                                    props.handlePlayPauseButton
                                }
                                playerState={playerState}
                                changeCurrentTime={changeCurrentTime}
                                subtitles={subtitles}
                                currentSubtitleIndex={currentSubtitleIndex}
                                handleSubtitle={handleSubtitle}
                                audios={audios}
                                currentAudioIndex={currentAudioIndex}
                                handleAudio={handleAudio}
                                timeForward={props.timeForward}
                                replay={props.replay}
                                fullscreen={props.fullscreen}
                                handleFullscreen={props.handleFullscreen}
                                setSettingsOpen={setSettingsOpen}
                                duration={duration}
                                handleMuted={props.handleMuted}
                                handleVolume={props.handleVolume}
                                setKeepVisible={setKeepVisible}
                                currentVolume={props.currentVolume}
                                playerTracksMode={props.playerTracksMode}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
