import { useState } from "react";

import { ReactComponent as ArrowDownIcon } from "@app/assets/icons/arrowDown.svg";
import { ReactComponent as Delete } from "@app/assets/icons/delete.svg";

import style from "@app/assets/styles/ebook.module.scss";
import { Bookmark } from "@d-i-t-a/reader/dist/types/model/Locator";

interface Props {
    setModalOpen: (value: boolean) => void;
    bookmarks: Bookmark[];
    setLocation?: (href: string, progression?: number) => void;
    deleteBookmark: (bookmark: Bookmark) => void;
}

export const BookmarksModal = (props: Props) => {
    const { setModalOpen, bookmarks, setLocation, deleteBookmark } = props;

    //const [isTocOpen, setTocOpen] = useState(false);
    // const [isBookmarkOpen, setBookmarkOpen] = useState(false);

    return (
        <div
            className={style.ebookBookmarksModalContainer}
            onClick={() => setModalOpen(false)}
        >
            <div
                className={style.ebookBookmarksModal}
                onClick={(e) => e.stopPropagation()}
            >
                <p className={style.ebookMenuTitle}>Bookmarks</p>
                {bookmarks.map((bookmark, index) => {
                    return (
                        <div className={style.bookmark}>
                            <button
                                onClick={() =>
                                    setLocation &&
                                    setLocation(
                                        bookmark.href,
                                        bookmark.locations.progression
                                    )
                                }
                                key={index}
                                className={style.bookmarkButton}
                            >
                                <p className={style.bookmarkTitle}>
                                    {bookmark.title}
                                </p>
                                {bookmark.locations.progression && (
                                    <p>
                                        {bookmark.locations.progression === 0
                                            ? "0%"
                                            : `${Math.round(
                                                  bookmark.locations
                                                      .progression * 100
                                              )}%`}
                                    </p>
                                )}
                            </button>
                            <button onClick={() => deleteBookmark && deleteBookmark(bookmark)}>
                                <Delete fill="red" width={20} height={20} />
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
