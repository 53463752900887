import { Video } from "../video/video-player";

declare const shaka: any;

export async function buildWidevineNetFilter(_player: any, video: Video) {
    return async (type: any, request: any) => {
        if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
            return;
        }

        if (!request.uris) {
            return;
        }

        // Extract drm info and build new url
        const drmExtra = video?.drmExtra ?? {};
        const drmServerUrl = request.uris?.[0];

        if (drmServerUrl == null) {
            return;
        }
        
        const urlObject = new URL(drmServerUrl);

        for (const key of Object.keys(drmExtra)) {    
            urlObject.searchParams.append(key, `${drmExtra[key]}`);
        }

        request.uris[0] = urlObject.toString();
    };
}
