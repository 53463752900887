import * as React from "react";

import classnames from "classnames";

interface Props {
    volumeOnFocus: (focus: boolean) => void;
    volumeFocus: boolean;
    currentVolume: number;
    handleVolume: (value: number) => void;
    style: any;
}

export default function VolumeBar(props: Props) {
    const { currentVolume, style } = props;
    const containerRef: any = React.createRef();

    const progressChange = (e: any) => {
        if (props.handleVolume) {
            const progress = parseInt(e.target.value, 10);
            props.handleVolume(progress);
        }
    };

    return (
        <div
            className={ classnames(style.volumeBarContainer, props.volumeFocus && style.volumeFocus) }
            ref={containerRef}
            onMouseEnter={() => props.volumeOnFocus(true)}
            onMouseLeave={() => props.volumeOnFocus(false)}
        >
            <input
                type="range"
                min="0"
                max="100"
                onChange={progressChange}
                value={String(currentVolume)}
                onKeyDown={(e) => {
                    if (e.keyCode !== 9) {
                        e.preventDefault();
                    }
                }}
            />
            <div>
                <div
                    className= { style.previousVolumeBar }
                    style={{ width: currentVolume + "%" }}
                />
            </div>
        </div>
    );
}
