import { DrmConfig, DrmExtra } from "@app/models/player";
import { TranslatorProps } from "@app/models/translator";
import { SyntheticEvent } from "react";

export interface SubtitleTrack {
    id: number;
    langCode: string;
}

export interface AudioTrack {
    id: number;
    langCode: string;
}

export interface LoadedMetadata {
    duration: number; // Float in seconds
    subtitleTracks?: SubtitleTrack[];
    selectedSubtitleTrackId?: number;
    audioTracks?: AudioTrack[];
    selectedAudioTrackId?: number;
}

export interface Video {
    url: string;
    drmConfig?: DrmConfig;
    drmExtra?: DrmExtra;
}

export interface VideoPlayerProps extends TranslatorProps {
    video: Video;
    autoPlay?: boolean;
    onPlay?: () => void;
    onPause?: () => void;
    onLoaded?: (loadedMetadata: LoadedMetadata) => void;
    onAudioTrackChange?: (id: number | undefined) => void; // track id
    onSubtitleTrackChange?: (id: number | undefined) => void; // track id
    onLoading?: () => void; // buffering
    onEnded?: () => void;
    onTimeUpdate?: (timecode: number) => void;
    onError?: (error: unknown) => void;
    playInline: boolean;
}

export interface VideoPlayerHandle {
    play: () => void;
    pause: () => void;
    seek: (timecode: number) => void; // timecode in seconds (float)
    selectAudioTrack: (id: number | undefined) => void; // Track number
    selectSubtitleTrack: (id: number | undefined) => void; // Track number
    muted: (muted: boolean) => void;
    volume: (volume: number) => void;
}

export function extendVideoProps(props: VideoPlayerProps) {
    return {
        onPlay: () => {
            props?.onPlay?.();
        },
        onPause: () => {
            props?.onPause?.();
        },
        onEnded: () => {
            props?.onEnded?.();
        },
        onTimeUpdate: (event: SyntheticEvent<HTMLVideoElement, Event>) => {
            props?.onTimeUpdate?.(event.currentTarget.currentTime);
        },
    };
}
