import { useEffect, useState } from "react";

import { PlayerState } from "./video/VideoPlayer";
import VolumeBar from "./video/VolumeBar";

import { ReactComponent as PlayIcon } from "@app/assets/icons/play.svg";
import { ReactComponent as PauseIcon } from "@app/assets/icons/pause.svg";
import { ReactComponent as ForwardIcon } from "@app/assets/icons/forward.svg";
import { ReactComponent as ReplayIcon } from "@app/assets/icons/replay.svg";
import { ReactComponent as VideoSettingsIcon } from "@app/assets/icons/videoSettings.svg";
import { ReactComponent as Fullscreen } from "@app/assets/icons/fullscreen.svg";
import { ReactComponent as FullscreenExit } from "@app/assets/icons/fullscreenExit.svg";
import { ReactComponent as Volume } from "@app/assets/icons/volume.svg";
import { ReactComponent as VolumeDown } from "@app/assets/icons/volumeDown.svg";
import { ReactComponent as VolumeMute } from "@app/assets/icons/volumeMute.svg";

import style from "@app/assets/styles/video-player.module.scss";

import { getName, getNativeName } from "@app/utils/iso-639-1";
import { secondsToDuration } from "@app/utils/time";

import { commonConfig as variant } from "@app/variant/variant-default";
import { useTranslation } from "react-i18next";

export interface SubtitleTrack {
    id: number;
    langCode: string;
}

export interface AudioTrack {
    id: number;
    langCode: string;
}

export interface PlayerProps {
    playerState: PlayerState;
    handlePlayPauseButton: () => void;
    subtitles?: SubtitleTrack[];
    currentSubtitleIndex: number;
    handleSubtitle: (id: number | undefined) => void;
    audios: AudioTrack[];
    currentAudioIndex: number;
    handleAudio: (id: number | undefined) => void;
    timeForward: (value: number) => void;
    replay: (value: number) => void;
    fullscreen: boolean;
    handleFullscreen: () => void;
    setSettingsOpen: (value: boolean) => void;
    duration: number;
    handleMuted: () => void;
    handleVolume: (value: number) => void;
    setKeepVisible: (value: boolean) => void;
    currentVolume: number;
    playerTracksMode: string;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export default function PlayerControls(props: PlayerProps) {
    const {
        playerState,
        audios = [],
        subtitles = [],
        currentAudioIndex,
        currentSubtitleIndex,
        fullscreen,
        handleAudio,
        handleSubtitle,
        handleFullscreen,
        handleMuted,
        duration,
        handleVolume,
        setKeepVisible,
        currentVolume,
        playerTracksMode,
    } = props;
    const [isSettingsDisplayed, setSettingsDisplayed] = useState(false);
    const [volumeFocus, setVolumeFocus] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    let buttonFocusTimeout: NodeJS.Timeout;

    const { t, i18n } = useTranslation();

    const displaySettings = audios.length > 1 || subtitles.length > 0;
    const jumpValue = variant.jumpValue;

    const buttonFocus = () => {
        clearTimeout(buttonFocusTimeout);
        setKeepVisible(true);
        buttonFocusTimeout = setTimeout(() => {
            setKeepVisible(false);
        }, 4000);
    };

    const volumeOnFocus = (focus: boolean) => {
        if (focus) {
            buttonFocus();
        }
        setVolumeFocus(focus);
    };

    const getLocalLangName = (langCode: string): string => {
        const langEn = getName(langCode);
        const exist = i18n.exists(
            `video-player.langNames.${langEn.toLowerCase()}`
        );
        return exist
            ? t(`video-player.langNames.${langEn.toLowerCase()}`)
            : langEn;
    };

    useEffect(() => {
        return () => {
            clearTimeout(buttonFocusTimeout);
        };
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const iconSize =
        windowDimensions.width < 700
            ? "10vw"
            : windowDimensions.width > 1500
            ? "3vw"
            : "6vw";

    return (
        <div className={style.controlsContainer}>
            <div className={style.playerControls}>
                {variant.backButton && (
                    <button onClick={() => props.replay(jumpValue)}>
                        <p className={style.jumpTimeText}>{jumpValue}</p>
                        <ReplayIcon
                            fill={
                                variant.cssVariables[
                                    "--button-second-text-color"
                                ]
                            }
                            width={iconSize}
                            height={iconSize}
                        />
                    </button>
                )}
                <button
                    onClick={() => props.handlePlayPauseButton()}
                    className={style.playButton}
                >
                    {playerState.play ? (
                        <PauseIcon
                            fill={
                                variant.cssVariables["--button-main-text-color"]
                            }
                            width={iconSize}
                            height={iconSize}
                        />
                    ) : (
                        <PlayIcon
                            fill={
                                variant.cssVariables["--button-main-text-color"]
                            }
                            width={iconSize}
                            height={iconSize}
                        />
                    )}
                </button>
                <button onClick={() => props.timeForward(jumpValue)}>
                    <p className={style.jumpTimeText}>{jumpValue}</p>
                    <ForwardIcon
                        fill={
                            variant.cssVariables["--button-second-text-color"]
                        }
                        width={iconSize}
                        height={iconSize}
                    />
                </button>
                <button
                    onClick={handleMuted}
                    className={style.volumeButton}
                    onMouseEnter={() => volumeOnFocus(true)}
                    onMouseLeave={() => volumeOnFocus(false)}
                >
                    {!currentVolume ? (
                        <VolumeMute
                            fill={
                                variant.cssVariables[
                                    "--button-second-text-color"
                                ]
                            }
                            width={iconSize}
                            height={iconSize}
                        />
                    ) : currentVolume < 50 ? (
                        <VolumeDown
                            fill={
                                variant.cssVariables[
                                    "--button-second-text-color"
                                ]
                            }
                            width={iconSize}
                            height={iconSize}
                        />
                    ) : (
                        <Volume
                            fill={
                                variant.cssVariables[
                                    "--button-second-text-color"
                                ]
                            }
                            width={iconSize}
                            height={iconSize}
                        />
                    )}
                </button>
                <VolumeBar
                    currentVolume={currentVolume}
                    handleVolume={handleVolume}
                    volumeOnFocus={volumeOnFocus}
                    volumeFocus={volumeFocus}
                    style={style}
                />
                <p className={style.duration}>
                    {secondsToDuration(playerState.currentTime, "hh:mm:ss") +
                        "/" +
                        secondsToDuration(duration, "hh:mm:ss")}
                </p>
            </div>
            <div className={style.playerSettings}>
                {displaySettings && (
                    <button
                        onClick={() => {
                            setSettingsDisplayed(!isSettingsDisplayed);
                            props.setSettingsOpen(!isSettingsDisplayed);
                        }}
                    >
                        <VideoSettingsIcon
                            fill={
                                variant.cssVariables[
                                    "--button-second-text-color"
                                ]
                            }
                            width={iconSize}
                            height={iconSize}
                        />
                    </button>
                )}
                {isSettingsDisplayed && (
                    <div className={style.settingsContainer}>
                        <div className={style.settingsColumn}>
                            <p>{t("video-player.audios")}</p>
                            <div className={style.settingsOptions}>
                                {audios.map((audio, index) => {
                                    return (
                                        <button
                                            onClick={() => handleAudio(index)}
                                            className={
                                                index === currentAudioIndex
                                                    ? style.selectedOptions
                                                    : style.settingsOptions
                                            }
                                            key={index}
                                        >
                                            {playerTracksMode === "local"
                                                ? getLocalLangName(
                                                      audio.langCode
                                                  )
                                                : getNativeName(audio.langCode)}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={style.settingsColumn}>
                            <p>{t("video-player.subtitles")}</p>
                            <div className={style.settingsOptions}>
                                {subtitles.map((subtitle, index) => {
                                    return (
                                        <button
                                            onClick={() =>
                                                handleSubtitle(index)
                                            }
                                            className={
                                                index === currentSubtitleIndex
                                                    ? style.selectedOptions
                                                    : style.settingsOptions
                                            }
                                            key={index}
                                        >
                                            {index === 0
                                                ? t("video-player.noSubtitle")
                                                : playerTracksMode === "local"
                                                ? getLocalLangName(
                                                      subtitle.langCode
                                                  )
                                                : getNativeName(
                                                      subtitle.langCode
                                                  )}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
                <button onClick={handleFullscreen}>
                    {fullscreen ? (
                        <FullscreenExit
                            fill={
                                variant.cssVariables[
                                    "--button-second-text-color"
                                ]
                            }
                            width={iconSize}
                            height={iconSize}
                        />
                    ) : (
                        <Fullscreen
                            fill={
                                variant.cssVariables[
                                    "--button-second-text-color"
                                ]
                            }
                            width={iconSize}
                            height={iconSize}
                        />
                    )}
                </button>
            </div>
        </div>
    );
}
