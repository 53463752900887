import { useEffect, useState } from "react";

import { secondsToDuration } from "@app/utils/time";
import { PlayerState } from "./video/VideoPlayer";

interface Props {
    playerState: PlayerState;
    duration: number;
    changeCurrentTime: (newTime: number) => void;
    style: any;
}

export function ProgressBar(props: Props) {
    const { playerState, duration, changeCurrentTime, style } = props;
    const [progression, setProgression] = useState(0);
    let configPlayer = {
        sliderStart: "None",
        sliderEnd: "None",
        durationFormat: "hh:mm:ss",
    };

    useEffect(() => {
        if (playerState.currentTime) {
            setProgression(
                (playerState.currentTime * 100) / playerState.duration
            );
        }
    }, [playerState.currentTime]);

    return (
        <div className={style.progressBarContainer}>
            <p className={style.timeLabel}>
                {configPlayer?.sliderStart === "Remaining"
                    ? secondsToDuration(
                          duration - playerState.currentTime,
                          configPlayer?.durationFormat
                      )
                    : configPlayer?.sliderStart === "Actual/Total"
                    ? secondsToDuration(
                          playerState.currentTime,
                          configPlayer?.durationFormat
                      ) +
                      "/" +
                      secondsToDuration(duration, configPlayer?.durationFormat)
                    : configPlayer?.sliderStart === "Total"
                    ? secondsToDuration(duration, configPlayer?.durationFormat)
                    : configPlayer?.sliderStart === "Actual"
                    ? secondsToDuration(
                          playerState.currentTime,
                          configPlayer?.durationFormat
                      )
                    : configPlayer?.sliderStart === "None"
                    ? undefined
                    : undefined}
            </p>
            <div className={style.progressBar}>
                <input
                    type="range"
                    min="0"
                    max="1000000"
                    value={progression * 10000}
                    onChange={(value) => {
                        const progress = +value.target.value / 10000;
                        setProgression(progress);
                        changeCurrentTime(progress);
                    }}
                />
                <div
                    style={{ width: progression + "%" }}
                    className={style.timePassed}
                />
                <div
                    style={{ left: progression + "%" }}
                    className={style.pointer}
                ></div>
            </div>
            <p className={style.timeLabel}>
                {configPlayer?.sliderEnd === "Remaining"
                    ? secondsToDuration(
                          duration - playerState.currentTime,
                          configPlayer?.durationFormat
                      )
                    : configPlayer?.sliderEnd === "Actual/Total"
                    ? secondsToDuration(
                          playerState.currentTime,
                          configPlayer?.durationFormat
                      ) +
                      " / " +
                      secondsToDuration(duration, configPlayer?.durationFormat)
                    : configPlayer?.sliderEnd === "Total"
                    ? secondsToDuration(duration, configPlayer?.durationFormat)
                    : configPlayer?.sliderEnd === "Actual"
                    ? secondsToDuration(
                          playerState.currentTime,
                          configPlayer?.durationFormat
                      )
                    : configPlayer?.sliderEnd === "None"
                    ? undefined
                    : undefined}
            </p>
        </div>
    );
}
