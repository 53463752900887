import { commonConfig as variantConfig } from "../variant/variant-default";

export function buildCssVariables() {
    const defaultColors = {
        "--dark-pink": "#D72968FF",
        "--slight-grey": "#DCDCDCFF",
        "--lighter-grey": "#CCCCCCFF",
        "--light-grey": "#555555FF",
        "--grey": "#808080FF",
        "--dark-grey": "#272727FF",
        "--dark-white": "#F5F5F5FF",
        "--red": "#FF0000FF",
        "--golden-yellow": "#EECD4BFF",
        "--orange": "#FFA500",
    };

    const themeColor = variantConfig.cssVariables["--theme-color"] ?? defaultColors["--dark-grey"];
    const highlightColor = variantConfig.cssVariables["--highlight-color"] ?? defaultColors["--dark-pink"];
    const highlightVariantColor = variantConfig.cssVariables["--highlight-variant-color"] ?? defaultColors["--dark-white"];
    const mainTextColor = variantConfig.cssVariables["--main-text-color"] ?? "white";
    const secondColor = variantConfig.cssVariables["--dark-white"] ?? defaultColors["--dark-pink"];
    const secondTextColor = variantConfig.cssVariables["--second-text-color"] ?? highlightVariantColor;
    const scrollbarColor = variantConfig.cssVariables["--scrollbar-color"] ?? defaultColors["--light-grey"];

    const defaultCssVariables = {
        "--theme-color": themeColor,

        "--button-main-background-color": highlightColor,
        "--button-main-text-color": mainTextColor,
        "--button-second-background-color": secondColor,
        "--button-second-text-color": secondTextColor,

        "--scrollbar-background-color": highlightVariantColor,
        "--scrollbar-thumb-color": scrollbarColor,

        "--player-controls-background-color": "rgba(0,0,0,0.5)",
        "--player-controls-slider-color": highlightColor,
        "--player-controls-text-color": mainTextColor,
        "--player-settings-text-color": "white",
        "--player-settings-background-color": "black",
        "--player-controls-progress-bar-color": highlightColor,
        "--player-controls-progress-bar-background-color": scrollbarColor,
        "--player-controls-progress-bar-cursor-color": highlightColor,
    };

    return Object.assign({}, defaultCssVariables, variantConfig.cssVariables);
}
