import { VideoPlayer } from "./VideoPlayer";
import { DrmConfig, DrmExtra, MediaDistributionUrl } from "@app/models/player";

type Props = {
    mediaDistributionUrl: MediaDistributionUrl;
    drmConfig?: DrmConfig;
    drmExtra?: DrmExtra;
    overlay?: { textLines: string[]; html: string };
}

export default function(props: Props) {
    return <VideoPlayer {...props} />;
}
