import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";

import { stringify as queryStringify } from "qs";

import { customTextDisplayerFactory } from "@app/components/shaka/text-displayer";

import styles from "@app/assets/styles/video-player.module.scss";

import {
    AudioTrack,
    extendVideoProps,
    SubtitleTrack,
    VideoPlayerHandle,
    VideoPlayerProps,
} from "./video-player";
import { buildWidevineNetFilter } from "../shaka/drm";

// const fairPlayInitDataTransform = (initData: any, initDataType: any, drmInfo: any) => {
//     if (initDataType != 'skd')
//       return initData;
//     console.log("####### initDataType", initDataType);
//     // 'initData' is a buffer containing an 'skd://' URL as a UTF-8 string.
//     const skdUri = shaka.util.StringUtils.fromBytesAutoDetect(initData);
//     const contentId = skdUri.split(";")[1];
//     console.log('contentId : ', contentId, drmInfo.serverCertificate);
//     const cert = drmInfo.serverCertificate;
//     return shaka.util.FairPlayUtils.initDataTransform(initData, contentId, cert);
//   }
/*
const fairPlayRequest = (type: any, request: any, context: any) => {
    if (type != shaka.net.NetworkingEngine.RequestType.LICENSE) {
      return;
    }

    // const originalPayload = new Uint8Array(request.body);
    // const base64Payload =
    //     shaka.util.Uint8ArrayUtils.toStandardBase64(originalPayload);
    // const params = 'spc=' + encodeURIComponent(base64Payload);
    request.headers['Content-Type'] = 'application/octet-stream';
    //request.body = shaka.util.StringUtils.toUTF8(params);
}*/

declare const shaka: any;

export const ShakaVideoPlayer = forwardRef<VideoPlayerHandle, VideoPlayerProps>(
    (props, ref) => {
        const { video, autoPlay, onError } = props;
        const containerRef = useRef<HTMLDivElement>(null);
        const videoRef = useRef<HTMLVideoElement | null>(null);
        const shakaRef = useRef(null);
        const [isVideoNodeReady, setVideoNodeReady] = useState<boolean>(false);

        const audioTracksRef = useRef<string | undefined>();
        const subtitleTracksRef = useRef<string | undefined>();

        useImperativeHandle(ref, () => ({
            play: () => {
                videoRef?.current?.play();
            },

            pause: () => {
                videoRef?.current?.pause();
            },

            seek: (timecode: number) => {
                if (videoRef.current) {
                    videoRef.current.currentTime = timecode;
                }
            },
            selectAudioTrack: (id: number | undefined) => {
                const shakaPlayer: any = shakaRef.current;

                if (shakaPlayer == null) {
                    return;
                }

                if (id != null) {
                    const shakaAudioLangs = shakaPlayer.getAudioLanguages();
                    shakaPlayer.selectAudioLanguage(shakaAudioLangs[id]);
                }

                if (props.onAudioTrackChange) {
                    props?.onAudioTrackChange(id);
                }
            },
            selectSubtitleTrack: (id: number | undefined) => {
                const shakaPlayer: any = shakaRef.current;

                if (shakaPlayer == null) {
                    return;
                }

                if (id != null) {
                    if (id > 0) {
                        const textLanguage =
                            shakaPlayer.getTextLanguages()?.[id - 1];
                        shakaPlayer.selectTextLanguage(textLanguage);
                        shakaPlayer.setTextTrackVisibility(true);
                    } else {
                        shakaPlayer.setTextTrackVisibility(false);
                    }
                }

                if (props.onSubtitleTrackChange) {
                    props?.onSubtitleTrackChange(id);
                }
            },
            muted: (muted: boolean) => {
                if (videoRef.current == null) {
                    return;
                }

                videoRef.current.muted = muted;
            },
            volume: (volume: number) => {
                if (videoRef.current == null) {
                    return;
                }

                videoRef.current.volume = volume;
            },
        }));

        // Error handler
        const handlePlayerError = useCallback((error: unknown) => {
            console.log("ERROR IN SHAKA ", error);
            if (onError != null) {
                onError(error);
            }
        }, []);

        const initTracks = useCallback((shakaPlayer: any) => {
            // Get active variant to get audio language
            const activeVariant = shakaPlayer
                .getVariantTracks()
                .find((variant: any) => variant.active);

            // Set state audios
            let defaultAudioId: number | undefined = undefined;
            const audioLangs = shakaPlayer.getAudioLanguages();

            // let defaultSubtitleId: number | undefined = undefined;

            // Check if text tracks are defined in manifest
            const textLangs = shakaPlayer.getTextLanguages();

            if ((JSON.stringify(audioLangs) !== audioTracksRef.current) || (JSON.stringify(textLangs) !== subtitleTracksRef.current)) {
                audioTracksRef.current = JSON.stringify(audioLangs);
                subtitleTracksRef.current = JSON.stringify(textLangs);

                const audioTracks: AudioTrack[] = [];

                audioLangs.forEach((langCode: string, index: number) => {
                    if (activeVariant?.language == langCode) {
                        defaultAudioId = index;
                    }

                    audioTracks.push({
                        id: index,
                        langCode,
                    });

                });

                const subtitleTracks: SubtitleTrack[] = [];

                if (textLangs.length > 0) {
                    subtitleTracks.push({ id: 0, langCode: "none" });
                    textLangs.forEach((language: string, index: number) => {
                        subtitleTracks.push({
                            id: index + 1,
                            langCode: language,
                        });
                    });
                    // } else {
                    //     video?.subtitles
                    //         ?.filter((subtitle) => {
                    //             const validUrls = (subtitle.urls ?? []).filter(
                    //                 (url: any) => !!url
                    //             );

                    //             return validUrls.length > 0;
                    //         })
                    //         ?.forEach(async (subtitle, index) => {
                    //             if (subtitle.language === "fr") {
                    //                 defaultSubtitleId = index;
                    //             }

                    //             const subtitleUrl = subtitle.urls[0];
                    //             let subtitleMimetype = "text/vtt";

                    //             if (getUrlFileExtension(subtitleUrl) === "srt") {
                    //                 subtitleMimetype = "text/srt";
                    //             }

                    //             await shakaPlayer.addTextTrackAsync(
                    //                 subtitleUrl,
                    //                 subtitle.language,
                    //                 "subtitles",
                    //                 subtitleMimetype
                    //             );
                    //             subtitleTracks.push({
                    //                 id: index,
                    //                 langCode: subtitle.language,
                    //             });
                    //         });
                }

                props?.onLoaded?.({
                    duration: videoRef.current
                        ? videoRef?.current?.duration
                        : 0,
                    subtitleTracks,
                    selectedSubtitleTrackId: 0,
                    audioTracks,
                    selectedAudioTrackId: defaultAudioId,
                });
            }
        }, []);

        const initShaka = useCallback(async () => {
            try {
                // Install built-in polyfills to patch browser incompatibilities.
                shaka.polyfill.installAll();
                shaka.polyfill.PatchedMediaKeysApple.install();
                const FairPlayUtils = shaka.util.FairPlayUtils;

                // Check to see if the browser supports the basic APIs Shaka needs.
                if (!shaka.Player.isBrowserSupported()) {
                    throw new Error(
                        "Your browser is not compatible with the player."
                    );
                }
                const shakaPlayer = new shaka.Player(videoRef.current);
                (window as any).shakaPlayer = shakaPlayer;
                shakaRef.current = shakaPlayer;
                shakaPlayer.addEventListener("error", handlePlayerError);
                shakaPlayer.addEventListener("trackschanged", () => {
                    initTracks(shakaPlayer);
                });
                shakaPlayer.addEventListener("variantchanged", () => {
                    initTracks(shakaPlayer);
                });

                // Configure player
                const playerConfig: any = {};
                const requestNetFilters: any[] = [];
                const responseNetFilters: any[] = [];

                if (video?.drmConfig != null) {
                    if (video.drmConfig.type == "widevine") {
                        playerConfig.drm = {
                            servers: {
                                "com.widevine.alpha": video.drmConfig.serverUrl,
                            },
                        };
                        const drmRequestNetFilter =
                            await buildWidevineNetFilter(shakaPlayer, video);

                        if (drmRequestNetFilter != null) {
                            requestNetFilters.push(drmRequestNetFilter);
                        }
                    } else if (video.drmConfig.type == "fairplay") {


                        playerConfig.drm = {
                            servers: {
                                "com.apple.fps.1_0":
                                    video.drmConfig.serverUrl + "?"
                                        + queryStringify(video.drmExtra ?? {}),
                            },
                            advanced: {
                                "com.apple.fps.1_0": {
                                    serverCertificateUri:
                                        video.drmConfig.fairplayCertificateUrl,
                                },
                            },
                            initDataTransform:
                                FairPlayUtils.ezdrmInitDataTransform,
                        };
                        requestNetFilters.push(
                            FairPlayUtils.ezdrmFairPlayRequest
                        );
                        responseNetFilters.push(
                            FairPlayUtils.commonFairPlayResponse
                        );
                    }
                }

                // Override min buffer time if defined
                playerConfig.manifest = {
                    dash: {
                        ignoreMinBufferTime: true,
                    },
                };
                playerConfig.streaming = {
                    alwaysStreamText: true,
                    rebufferingGoal: 8,
                    bufferingGoal: 30,
                    ignoreTextStreamFailures: true,
                };

                // Configure subtitle
                playerConfig.textDisplayFactory = customTextDisplayerFactory(
                    videoRef.current,
                    containerRef.current
                );

                // Everything looks good!
                shakaPlayer.configure(playerConfig);

                // Register network filters
                const nwe = shakaPlayer.getNetworkingEngine();
                requestNetFilters.forEach((netFilter: any) => {
                    nwe.registerRequestFilter(netFilter);
                });
                responseNetFilters.forEach((netFilter: any) => {
                    nwe.registerResponseFilter(netFilter);
                });

                // Load video manifest
                await shakaPlayer.load(video.url);
            } catch (error) {
                handlePlayerError(error);
            }
        }, []);

        // Used to initialize player
        const initVideoRef = useCallback((node: any) => {
            if (node == null) {
                return;
            }

            videoRef.current = node;
            setVideoNodeReady(true);
        }, []);

        // Mount/unmount player
        useEffect(() => {
            initShaka().then(() => {
                console.log("video launched");
            });

            return () => {
                const removePlayer = async () => {
                    if (shakaRef.current) {
                        await (shakaRef.current as any).detach();
                    }
                };

                removePlayer().catch((error) => {
                    console.log(error);
                });
            };
        }, [isVideoNodeReady]);

        const eventVideoProps = useMemo(() => {
            return extendVideoProps(props);
        }, [props.onPlay, props.onPause, props.onEnded, props.onTimeUpdate]);

        return (
            <div ref={containerRef} className={styles.playableVideoContainer}>
                <video
                    {...eventVideoProps}
                    ref={initVideoRef}
                    autoPlay={!!autoPlay}
                    onWaiting={props.onLoading}
                    playsInline={props.playInline}
                ></video>
            </div>
        );
    }
);
