import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { Audio, Oval } from "react-loader-spinner";

import { Audiobook, AudiobookContent, AudiobookLink } from "./audiobook-player";

import { secondsToDuration } from "@app/utils/time";

import { ReactComponent as PlayIcon } from "@app/assets/icons/play.svg";
import { ReactComponent as PauseIcon } from "@app/assets/icons/pause.svg";
import { ReactComponent as Volume } from "@app/assets/icons/volume.svg";
import { ReactComponent as VolumeDown } from "@app/assets/icons/volumeDown.svg";
import { ReactComponent as VolumeMute } from "@app/assets/icons/volumeMute.svg";
import { ReactComponent as SkipNext } from "@app/assets/icons/skipNext.svg";
import { ReactComponent as SkipPrevious } from "@app/assets/icons/skipPrevious.svg";

import VolumeBar from "@app/components/video/VolumeBar";
import { ProgressBar } from "@app/components/ProgressBar";

import style from "@app/assets/styles/audiobook.module.scss";

import { MediaDistributionUrl } from "@app/models/player";

import { commonConfig as variant } from "@app/variant/variant-default";

interface State {
    muted: boolean;
    currentVolume: number;
    previousVolume: number;
}

export interface PlayerState {
    play: boolean;
    ended: boolean;
    duration: number;
    loading: boolean;
    hiding: boolean;
    onTimeChanging: boolean;
    onTimeChangingLeftOutOfBound: boolean;
    onTimeChangingRightOutOfBound: boolean;
    onFastForward: boolean;
    totalMoveTime: number;
    currentTime: number;
    showDoubleChevron: boolean;
    lastFastMove: number;
    sessionDuration: number;
    sessionLastStartDate: any;
}

const playerInfoState: State = {
    muted: false,
    currentVolume: 100,
    previousVolume: 100,
};

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

interface PlayerProps {
    url: string;
    audiobook: Audiobook;
    viewMode: string;
}

const AudiobookPlayerView = (props: PlayerProps) => {
    const { audiobook, url, viewMode } = props;

    const [selectedContent, setSelectedContent] = useState(
        audiobook?.readingOrder[0]
    );
    const [, setUpdatedAt] = useState(0);
    const [state, setState] = useState<State>(playerInfoState);
    const [volumeFocus, setVolumeFocus] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );
    const [preventFirstPlay, setPreventFirstPlay] = useState(true);

    let audioRef = useRef<any>(null);

    const changeTrack = (content: AudiobookContent) => {
        audioRef.current.pause();
        setSelectedContent(content);
        audioRef.current.load();
        playerState.loading = true;
        forceRender();
    };

    const playerState = useRef<PlayerState>({
        play: false,
        ended: false,
        loading: true,
        duration: 0,
        hiding: false,
        onTimeChanging: false,
        onFastForward: false,
        onTimeChangingLeftOutOfBound: false,
        onTimeChangingRightOutOfBound: false,
        totalMoveTime: 0,
        currentTime: 0,
        showDoubleChevron: false,
        lastFastMove: 0,
        sessionDuration: 0,
        sessionLastStartDate: 0,
    }).current;

    let bufferingTimer: any;

    const inIframe = useMemo(() => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }, []);

    const sendPostMessage = useCallback(
        (type: string, data?: any) => {
            if (inIframe) {
                window.parent.postMessage(
                    {
                        type,
                        data,
                    },
                    "*"
                );
            }
        },
        [inIframe]
    );

    const forceRender = useCallback(() => {
        const now = new Date();
        setUpdatedAt(now.getTime());
    }, []);

    const setAudioRef = (node: HTMLAudioElement | null) => {
        if (!node || audioRef.current) {
            return;
        }
        audioRef.current = node;
        forceRender();
    };

    const handleLoaded = useCallback(() => {
        playerState.loading = false;
        if (preventFirstPlay) {
            setPreventFirstPlay(false);
        } else {
            audioRef.current.play();
            playerState.play = true;
        }
        if (
            playerState.duration > 0 &&
            playerState.duration === audioRef.current.duration
        ) {
            forceRender();
            return;
        }
        const duration = audioRef.current.duration;
        playerState.duration = duration;
        sendPostMessage("ready");
        forceRender();
    }, [preventFirstPlay]);

    const timeForward = (value: number) => {
        const time = playerState.currentTime;
        if (time + value > playerState.duration) {
            playerState.onTimeChangingRightOutOfBound = true;
        } else {
            playerState.onTimeChangingRightOutOfBound = false;
        }
        if (!playerState.onTimeChangingRightOutOfBound) {
            audioRef.current.currentTime = time + value;
        }
    };
    const replay = (value: number) => {
        const time = playerState.currentTime;
        if (time - value < 0) {
            playerState.onTimeChangingLeftOutOfBound = true;
        } else {
            playerState.onTimeChangingLeftOutOfBound = false;
        }
        if (!playerState.onTimeChangingLeftOutOfBound) {
            audioRef.current.currentTime = time - value;
        }
    };

    const handlePlayPauseButton = () => {
        if (audioRef.current?.paused) {
            sendPostMessage("playing", playerState.currentTime);
            audioRef.current.play();
            playerState.play = true;
        } else {
            sendPostMessage("paused", playerState.currentTime);
            audioRef.current?.pause();
            playerState.play = false;
        }
        forceRender();
    };

    const handleMuted = useCallback(() => {
        const newValue = !state.muted;
        sendPostMessage("volume-changed", newValue ? 0 : state.previousVolume);
        audioRef.current.muted = newValue;
        const currentVolume = state.muted ? state.previousVolume : 0;
        audioRef.current.volume = currentVolume / 100;
        setState((prevState) => {
            return {
                ...prevState,
                muted: newValue,
                previousVolume: state.currentVolume,
                currentVolume: currentVolume,
            };
        });
    }, [state]);

    const handleVolume = useCallback(
        (value: number) => {
            if (audioRef.current) {
                sendPostMessage("volume-changed", value);
                audioRef.current.volume = value / 100;
                const muted = value ? false : true;
                audioRef.current.muted = muted;
                setState((prevState) => {
                    return {
                        ...prevState,
                        muted: muted,
                        previousVolume: value ? state.previousVolume : 50,
                        currentVolume: value,
                    };
                });
            }
        },
        [state]
    );

    const changeCurrentTime = (time: number) => {
        audioRef.current.currentTime = (time * playerState.duration) / 100;
    };

    const seek = (time: number) => {
        audioRef.current.currentTime = time;
    };

    const handleTimeUpdate = useCallback((timecode: number) => {
        forceRender();
        playerState.currentTime = timecode;
    }, []);

    const handleEnded = () => {
        sendPostMessage("playback-finished");
        const currentTocIndex = audiobook.readingOrder.findIndex(
            (content) => selectedContent === content
        );
        if (audiobook.readingOrder[currentTocIndex + 1]) {
            changeTrack(audiobook.readingOrder[currentTocIndex + 1]);
        }
    };

    const handlePrevious = () => {
        const currentTocIndex = audiobook.readingOrder.findIndex(
            (content) => selectedContent === content
        );
        if (audiobook.readingOrder[currentTocIndex - 1]) {
            changeTrack(audiobook.readingOrder[currentTocIndex - 1]);
        }
    };

    const handleNext = () => {
        const currentTocIndex = audiobook.readingOrder.findIndex(
            (content) => selectedContent === content
        );
        if (audiobook.readingOrder[currentTocIndex + 1]) {
            changeTrack(audiobook.readingOrder[currentTocIndex + 1]);
        }
    };

    const handleBuffering = () => {
        playerState.loading = true;
        forceRender();
    };

    const handleKey = (e: any) => {
        let intercepted = false;

        switch (e.code) {
            case "ArrowLeft":
                replay(variant.jumpValue);
                intercepted = true;
                break;
            case "ArrowRight":
                timeForward(variant.jumpValue);
                intercepted = true;
                break;
            case "ArrowUp":
                handleVolume(
                    state.currentVolume > 95 ? 100 : state.currentVolume + 5
                );
                intercepted = true;
                break;
            case "ArrowDown":
                handleVolume(
                    state.currentVolume < 5 ? 0 : state.currentVolume - 5
                );
                intercepted = true;
                break;
            case "Space":
                handlePlayPauseButton();
                intercepted = true;
                break;
            // M key
            case "Semicolon":
                handleMuted();
                forceRender();
                intercepted = true;
                break;
        }

        if (intercepted) {
            // Cancel bubbles
            e.preventDefault();
            return false;
        }
    };

    const handleWebEvents = useCallback((event: MessageEvent<any>) => {
        switch (event.data.type) {
            case "play":
                audioRef.current.play();
                sendPostMessage("playing", playerState.currentTime);
                break;
            case "pause":
                audioRef.current.pause();
                sendPostMessage("paused", playerState.currentTime);
                break;
            case "volume-change":
                handleVolume(event.data.data);
                break;
            case "seek":
                seek(event.data.data);
                break;
            default:
                console.log("Event type ", event.data.type, " is unknow");
                break;
        }
    }, []);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.addEventListener("waiting", handleBuffering);
        }
    }, [audioRef.current]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        if (inIframe) {
            window.addEventListener("message", (event) =>
                handleWebEvents(event)
            );
        }
        return () => {
            window.removeEventListener("resize", handleResize);
            window.addEventListener("message", (event) =>
                handleWebEvents(event)
            );
        };
    }, []);

    useEffect(() => {
        window.addEventListener("keyup", handleKey);
        return () => {
            window.removeEventListener("keyup", handleKey);
        };
    }, [state]);

    const iconSize =
        windowDimensions.width < 700
            ? "7vw"
            : windowDimensions.width > 1500
            ? "3vw"
            : "6vw";

    return viewMode === "mini" ? (
        <div className={style.audiobookPlayerMini}>
            <p className={style.title}>{audiobook.metadata.title} - {selectedContent.title}</p>
            <p className={style.author}>{audiobook.metadata.author}</p>
            <ProgressBar
                playerState={playerState}
                duration={playerState.duration}
                changeCurrentTime={changeCurrentTime}
                style={style}
            />
            <div className={style.controls}>
                <button onClick={handlePrevious}>
                    <SkipPrevious
                        width={iconSize}
                        height={iconSize}
                        fill="black"
                    />
                </button>
                {playerState.loading ? (
                    <Oval
                        height={iconSize}
                        width={iconSize}
                        color="#000000"
                        wrapperClass={style.loader}
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#000000"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                    />
                ) : (
                    <button onClick={handlePlayPauseButton}>
                        {audioRef.current?.paused ? (
                            <PlayIcon
                                width={iconSize}
                                height={iconSize}
                                fill="black"
                            />
                        ) : (
                            <PauseIcon
                                width={iconSize}
                                height={iconSize}
                                fill="black"
                            />
                        )}
                    </button>
                )}
                <button onClick={handleNext}>
                    <SkipNext width={iconSize} height={iconSize} fill="black" />
                </button>
            </div>
            <audio
                ref={(node) => setAudioRef(node)}
                onTimeUpdate={(event) =>
                    handleTimeUpdate(event.currentTarget.currentTime)
                }
                onEnded={handleEnded}
                onCanPlay={() => handleLoaded()}
            >
                <source src={url + "/" + selectedContent?.href} />
                Your browser does not support the audio element.
            </audio>
        </div>
    ) : (
        <div className={style.audiobookPlayer}>
            <div className={style.metadataAndPlayer}>
                <p className={style.title}>{audiobook.metadata.title}</p>
                <p className={style.author}>{audiobook.metadata.author}</p>
                <img
                    src={
                        url +
                        "/" +
                        audiobook.links.find(
                            (link: AudiobookLink) => link.type === "image/jpeg"
                        )?.href
                    }
                    alt=""
                    className={style.cover}
                />
                <div className={style.audioPlayer}>
                    <div className={style.audioLayout}>
                        <ProgressBar
                            playerState={playerState}
                            duration={playerState.duration}
                            changeCurrentTime={changeCurrentTime}
                            style={style}
                        />
                        <div className={style.audioControls}>
                            {playerState.loading ? (
                                <Oval
                                    height={iconSize}
                                    width={iconSize}
                                    color="#000000"
                                    wrapperClass={style.loader}
                                    visible={true}
                                    ariaLabel="oval-loading"
                                    secondaryColor="#000000"
                                    strokeWidth={5}
                                    strokeWidthSecondary={5}
                                />
                            ) : (
                                <button onClick={handlePlayPauseButton}>
                                    {audioRef.current?.paused ? (
                                        <PlayIcon
                                            width={iconSize}
                                            height={iconSize}
                                            fill="black"
                                        />
                                    ) : (
                                        <PauseIcon
                                            width={iconSize}
                                            height={iconSize}
                                            fill="black"
                                        />
                                    )}
                                </button>
                            )}
                            <button
                                onClick={handleMuted}
                                className={style.volumeButton}
                                onMouseEnter={() => setVolumeFocus(true)}
                                onMouseLeave={() => setVolumeFocus(false)}
                            >
                                {!state.currentVolume ? (
                                    <VolumeMute
                                        fill="black"
                                        width={iconSize}
                                        height={iconSize}
                                    />
                                ) : state.currentVolume < 50 ? (
                                    <VolumeDown
                                        fill="black"
                                        width={iconSize}
                                        height={iconSize}
                                    />
                                ) : (
                                    <Volume
                                        fill="black"
                                        width={iconSize}
                                        height={iconSize}
                                    />
                                )}
                            </button>
                            <VolumeBar
                                currentVolume={state.currentVolume}
                                handleVolume={handleVolume}
                                volumeOnFocus={setVolumeFocus}
                                volumeFocus={volumeFocus}
                                style={style}
                            />
                            <p className={style.duration}>
                                {secondsToDuration(
                                    playerState.currentTime,
                                    "hh:mm:ss"
                                ) +
                                    "/" +
                                    secondsToDuration(
                                        playerState.duration,
                                        "hh:mm:ss"
                                    )}
                            </p>
                        </div>

                        <audio
                            ref={(node) => setAudioRef(node)}
                            onTimeUpdate={(event) =>
                                handleTimeUpdate(
                                    event.currentTarget.currentTime
                                )
                            }
                            onEnded={handleEnded}
                            onCanPlay={() => handleLoaded()}
                        >
                            <source src={url + "/" + selectedContent?.href} />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                </div>
            </div>
            <div className={style.contents}>
                <p className={style.contentsTitle}>Reading order</p>
                {audiobook?.readingOrder.map((content, index) => {
                    return (
                        <button
                            className={style.content}
                            onClick={() => changeTrack(content)}
                            key={index}
                        >
                            <div className={style.left}>
                                <p
                                    style={
                                        selectedContent === content
                                            ? { fontWeight: "bold" }
                                            : {}
                                    }
                                >
                                    {content.title}
                                </p>
                                {selectedContent === content &&
                                    (playerState.loading ? (
                                        <Oval
                                            height={20}
                                            width={20}
                                            color="#000000"
                                            wrapperClass={style.playingLogo}
                                            visible={true}
                                            ariaLabel="oval-loading"
                                            secondaryColor="#000000"
                                            strokeWidth={5}
                                            strokeWidthSecondary={5}
                                        />
                                    ) : playerState.play ? (
                                        <Audio
                                            height="20"
                                            width="20"
                                            color="#000000"
                                            ariaLabel="audio-loading"
                                            wrapperClass={style.playingLogo}
                                            visible={true}
                                        />
                                    ) : (
                                        <PauseIcon
                                            width={20}
                                            height={20}
                                            fill="black"
                                            className={style.playingLogo}
                                        />
                                    ))}
                            </div>
                            <p>
                                {secondsToDuration(
                                    content.duration,
                                    "hh:mm:ss"
                                )}
                            </p>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

type Props = {
    mediaDistributionUrl: MediaDistributionUrl;
    viewMode: string;
};

export const AudiobookPlayer = (props: Props) => {
    const manifestUrl = props.mediaDistributionUrl.url;
    const url = manifestUrl.replace("/manifest.json", "");

    const audiobookQuery = useQuery(
        ["audiobook", manifestUrl],
        async () => {
            const response = await fetch(manifestUrl);
            return response.json();
        },
        { enabled: !!manifestUrl }
    );

    return audiobookQuery.data ? (
        <AudiobookPlayerView
            url={url}
            audiobook={audiobookQuery.data}
            viewMode={props.viewMode}
        />
    ) : (
        <div className={style.emptyLoader}>
            <Oval
                height={100}
                width={100}
                color="#000000"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#000000"
                strokeWidth={5}
                strokeWidthSecondary={5}
            />
        </div>
    );
};
