import React, {
    createRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { useWindowSize } from "usehooks-ts";

import PlayerLayout from "./PlayerLayout";

import dayjs from "dayjs";
import { BasicVideoPlayer } from "./BasicVideoPlayer";
import {
    AudioTrack,
    LoadedMetadata,
    SubtitleTrack,
    VideoPlayerHandle,
} from "./video-player.js";
import { ShakaVideoPlayer } from "./ShakaVideoPlayer";

import PlayerControls from "@app/fragments/PlayerControls";

import styles from "@app/assets/styles/video-player.module.scss";
import { DrmConfig, DrmExtra, MediaDistributionUrl } from "@app/models/player";

import { commonConfig as variant } from "@app/variant/variant-default";

export interface VideoPlayerProps {
    mediaDistributionUrl: MediaDistributionUrl;
    drmConfig?: DrmConfig;
    drmExtra?: DrmExtra;
    timecode?: number;
    playerTracksMode?: string;
    overlay?: { textLines: string[]; html: string };
}

interface State {
    subtitles?: SubtitleTrack[];
    currentSubtitleIndex: number;
    subtitle: boolean;
    audios?: AudioTrack[];
    currentAudioIndex: number;
    muted: boolean;
    currentVolume: number;
    previousVolume: number;
}

export interface PlayerState {
    play: boolean;
    ended: boolean;
    duration: number;
    loading: boolean;
    hiding: boolean;
    onTimeChanging: boolean;
    onTimeChangingLeftOutOfBound: boolean;
    onTimeChangingRightOutOfBound: boolean;
    onFastForward: boolean;
    totalMoveTime: number;
    currentTime: number;
    showDoubleChevron: boolean;
    lastFastMove: number;
    sessionDuration: number;
    sessionLastStartDate: any;
}

const playerInfoState: State = {
    subtitles: [],
    currentSubtitleIndex: -1,
    subtitle: false,
    audios: [],
    currentAudioIndex: 0,
    muted: false,
    currentVolume: 100,
    previousVolume: 100,
};

const RATIO_16_9 = 16 / 9;

export function VideoPlayer(props: VideoPlayerProps) {
    const { mediaDistributionUrl } = props;
    const [state, _setState] = useState<State>(playerInfoState);
    const [fullscreen, setFullscreen] = useState(false);
    const [mouseActivity, setMouseActivity] = useState(false);
    const [playInline, setPlayInline] = useState(true);
    const { width: winWidth, height: winHeight } = useWindowSize();

    const useBasicPlayer = useMemo(() => {
        return mediaDistributionUrl.contentType.startsWith("video/");
    }, [mediaDistributionUrl.contentType]);

    const playerRef = useRef<VideoPlayerHandle | undefined>();
    let mainElement: any = createRef();
    const myStateRef = useRef(state);
    const lastPeriodicTime = useRef<number | undefined>();
    const lastPosBeforeLoading = useRef<number | undefined>();

    let mouseActivityTimer: string | number | NodeJS.Timeout | undefined;
    let clickVideoTimeout: any;

    const [, setUpdatedAt] = useState(0);

    const setState = (data: State) => {
        myStateRef.current = data;
        _setState(data);
    };

    const videoContainerWidth = useMemo(() => {
        const ratio = winWidth / winHeight;

        if (ratio > RATIO_16_9) {
            const newWidth =
                Math.round((100 / (ratio / RATIO_16_9)) * 100) / 100;
            return newWidth + "%";
        }

        return "100%";
    }, [winWidth, winHeight]);

    const forceRender = useCallback(() => {
        const now = new Date();
        setUpdatedAt(now.getTime());
    }, []);

    const playerState = useRef<PlayerState>({
        play: false,
        ended: false,
        loading: true,
        duration: 0,
        hiding: false,
        onTimeChanging: false,
        onFastForward: false,
        onTimeChangingLeftOutOfBound: false,
        onTimeChangingRightOutOfBound: false,
        totalMoveTime: 0,
        currentTime: 0,
        showDoubleChevron: false,
        lastFastMove: 0,
        sessionDuration: 0,
        sessionLastStartDate: 0,
    }).current;

    const inIframe = useMemo(() => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }, []);

    const sendPostMessage = useCallback((type: string, data?: any) => {
        if (inIframe) {
            window.parent.postMessage(
                {
                    type,
                    data
                },
                "*"
            );
        }
    }, [inIframe]);

    const handleSubtitle = useCallback((trackIndex: number) => {
        playerRef?.current?.selectSubtitleTrack(
            trackIndex == -1 ? undefined : trackIndex
        );
    }, []);

    const handleAudio = useCallback((audioIndex: number) => {
        playerRef?.current?.selectAudioTrack(
            audioIndex == -1 ? undefined : audioIndex
        );
    }, []);

    const handlePlayerError = useCallback((event: any) => {
        console.error("Player error", event);
    }, []);

    const onPlayChange = (givenPlay?: boolean) => {
        const newPlay = givenPlay !== undefined ? givenPlay : !playerState.play;
        if (newPlay) {
            sendPostMessage("playing", playerState.currentTime);
            playerRef.current?.play();
        } else {
            sendPostMessage("paused", playerState.currentTime)
            playerRef.current?.pause();
        }
        playerState.play = newPlay;
        forceRender();
    };

    const changeCurrentTime = (time: number) => {
        playerRef.current?.seek((time * playerState.duration) / 100);
    };

    const seek = (time: number) => {
        playerRef.current?.seek(time);
    };

    const timeForward = (value: number) => {
        const time = playerState.currentTime;
        if (time + value > playerState.duration) {
            playerState.onTimeChangingRightOutOfBound = true;
        } else {
            playerState.onTimeChangingRightOutOfBound = false;
        }
        if (!playerState.onTimeChangingRightOutOfBound) {
            playerRef.current?.seek(time + value);
        }
    };
    const replay = (value: number) => {
        const time = playerState.currentTime;
        if (time - value < 0) {
            playerState.onTimeChangingLeftOutOfBound = true;
        } else {
            playerState.onTimeChangingLeftOutOfBound = false;
        }
        if (!playerState.onTimeChangingLeftOutOfBound) {
            playerRef.current?.seek(time - value);
        }
    };

    const sendAudioTracks = useCallback(() => {
        sendPostMessage("audio-track-list", myStateRef.current.audios);
    }, [state.audios]);

    const sendSubtitles = useCallback(() => {
        sendPostMessage("subtitle-track-list", myStateRef.current.subtitles);
    }, [state.subtitles]);

    const sendPosition = (eventName: string) => {
        sendPostMessage(eventName, playerState.currentTime);
    };

    const handleWebEvents = useCallback((event: MessageEvent<any>) => {
        switch (event.data.type) {
            case "play":
                onPlayChange(true);
                break;
            case "pause":
                onPlayChange(false);
                break;
            case "fullscreen":
                handleFullscreen();
                break;
            case "volume-change":
                handleVolume(event.data.data);
                break;
            case "audio-track-list":
                sendAudioTracks();
                break;
            case "audio-track-change":
                handleAudioTrackChange(event.data.data);
                break;
            case "subtitle-track-list":
                sendSubtitles();
                break;
            case "subtitle-track-change":
                handleSubtitle(event.data.data);
                break;
            case "seek":
                seek(event.data.data);
                break;
            case "get-position":
                sendPosition("position");
                break;
            default:
                console.log("Event type ", event.data.type, " is unknow");
                break;
        }
    }, []);

    const addListeners = () => {
        document.addEventListener("mousemove", handleMouseActivity);
        document.addEventListener("keyup", handleKey);
    };

    const removeListeners = () => {
        document.removeEventListener("mousemove", handleMouseActivity);
        document.removeEventListener("keyup", handleKey);
    };

    const handleMouseActivity = () => {
        clearTimeout(mouseActivityTimer);
        setMouseActivity(true);
        mouseActivityTimer = setTimeout(() => {
            setMouseActivity(false);
        }, 2000);
    };

    useEffect(() => {
        addListeners();

        return () => {
            clearTimeout(mouseActivityTimer);
            clearTimeout(clickVideoTimeout);
            removeListeners();
        };
    }, [state, fullscreen]);

    useEffect(() => {
        if (inIframe) {
            window.addEventListener("message", (event) => handleWebEvents(event));
        }
        return () =>
            window.removeEventListener("message", (event) =>
                handleWebEvents(event)
            );
    }, []);

    const handlePlayPauseButton = () => {
        if (playerState.play) {
            onPlayChange(false);
            return;
        }

        onPlayChange(true);

        if (playerState.onTimeChanging) {
            changeCurrentTime(playerState.currentTime);
            playerState.onTimeChanging = false;
            playerState.onTimeChangingLeftOutOfBound = false;
            playerState.onTimeChangingRightOutOfBound = false;
            playerState.totalMoveTime = 0;
        }
        forceRender();
    };

    const handleEnded = useCallback(() => {
        sendPostMessage("playback-finished");
        onPlayChange(false);
    }, []);

    const handleLoaded = useCallback((loadedMetadata: LoadedMetadata) => {
        const duration = loadedMetadata.duration;

        if (props.timecode != null) {
            const timecode = Math.max(0, Math.min(props.timecode, duration));
            changeCurrentTime(timecode);
        }

        playerState.duration = duration;
        playerState.loading = false;
        setState({
            ...state,
            subtitles: loadedMetadata?.subtitleTracks,
            currentSubtitleIndex: loadedMetadata?.selectedSubtitleTrackId ?? -1,
            audios: loadedMetadata?.audioTracks,
            currentAudioIndex: loadedMetadata?.selectedAudioTrackId ?? 0,
        });
        sendPostMessage("ready");
    }, []);

    const handlePlay = useCallback(() => {
        if (playerState.play === false) {
            playerState.play = true;
        }
        playerState.sessionLastStartDate = dayjs();
    }, []);

    const handlePause = useCallback(() => {
        if (playerState.play === true) {
            playerState.play = false;
        }
        playerState.sessionDuration += dayjs().diff(
            playerState.sessionLastStartDate
        );
    }, []);

    const handleTimeUpdate = useCallback((timecode: number) => {
        forceRender();
        playerState.currentTime = timecode;
    }, []);

    const handleAudioTrackChange = useCallback((id: number | undefined) => {
        sendPostMessage("audio-track-changed", id);
        setState({ ...myStateRef.current, currentAudioIndex: id ?? 0 });
    }, []);

    const handleSubtitleTrackChange = useCallback((id: number | undefined) => {
        sendPostMessage("subtitle-track-changed", id);
        setState({ ...myStateRef.current, currentSubtitleIndex: id ?? -1 });
    }, []);

    const handleMuted = useCallback(() => {
        const newValue = !state.muted;
        sendPostMessage("volume-changed", newValue ? 0 : myStateRef.current.previousVolume);
        playerRef?.current?.muted(newValue);
        const currentVolume = state.muted ? state.previousVolume : 0;
        playerRef?.current?.volume(currentVolume / 100);
        setState({
            ...state,
            muted: newValue,
            previousVolume: state.currentVolume,
            currentVolume: currentVolume,
        });
    }, [state]);

    const handleVolume = useCallback(
        (value: number) => {
            if (playerRef.current) {
                sendPostMessage("volume-changed", value);
                playerRef.current.volume(value / 100);
                const muted = value ? false : true;
                playerRef.current.muted(muted);
                setState({
                    ...state,
                    muted: muted,
                    previousVolume: value ? state.previousVolume : 50,
                    currentVolume: value,
                });
            }
        },
        [state]
    );

    const getProgression = () => {
        return (playerState.currentTime * 100) / playerState.duration;
    };

    const handleFullscreen = async () => {
        if (!fullscreen) {
            try {
                sendPostMessage("view-mode-changed", "fullscreen");
                if (document.fullscreenEnabled) {
                    await mainElement.requestFullscreen();
                    setFullscreen(true);
                } else {
                    setPlayInline(false);
                }
            } catch (error) {
                console.log("Fullscreen error ", error);
            }
        } else {
            sendPostMessage("view-mode-changed", "inline");
            if (document.fullscreenEnabled) {
                await document.exitFullscreen();
                setFullscreen(false);
            }
        }
        // setFullscreen(!fullscreen);
    };

    const onClickVideo = () => {
        window.clearTimeout(clickVideoTimeout);
        clickVideoTimeout = window.setTimeout(() => {
            handlePlayPauseButton();
        }, 200);
    };

    const onDoubleClickVideo = () => {
        window.clearTimeout(clickVideoTimeout);
        clickVideoTimeout = window.setTimeout(() => {
            handleFullscreen();
        }, 200);
    };

    const handleBuffering = () => {
        playerState.loading = true;
        forceRender();
    };

    const handleEndBuffering = () => {
        playerState.loading = false;
    }

    useEffect(() => {
        if (!lastPeriodicTime.current || (playerState.currentTime > (lastPeriodicTime.current + 5)) || (playerState.currentTime < (lastPeriodicTime.current - 5))) {
            sendPosition("periodic-position");
            lastPeriodicTime.current = playerState.currentTime;
        }
    }, [playerState.currentTime]);

    const handleKey = (e: any) => {
        let intercepted = false;

        switch (e.code) {
            case "ArrowLeft":
                replay(variant.jumpValue);
                intercepted = true;
                break;
            case "ArrowRight":
                timeForward(variant.jumpValue);
                intercepted = true;
                break;
            case "ArrowUp":
                handleVolume(
                    state.currentVolume > 95 ? 100 : state.currentVolume + 5
                );
                intercepted = true;
                break;
            case "ArrowDown":
                handleVolume(
                    state.currentVolume < 5 ? 0 : state.currentVolume - 5
                );
                intercepted = true;
                break;
            case "Space":
                handlePlayPauseButton();
                intercepted = true;
                break;
            case "KeyF":
                handleFullscreen();
                intercepted = true;
                break;
            // M key
            case "Semicolon":
                handleMuted();
                forceRender();
                intercepted = true;
                break;
        }

        if (intercepted) {
            // Cancel bubbles
            e.preventDefault();
            return false;
        }
    };

    const video = {
        url: mediaDistributionUrl.url,
        drmConfig: props.drmConfig,
        drmExtra: props.drmExtra,
    };

    let VideoPlayer = BasicVideoPlayer;

    if (useBasicPlayer) {
        // Basic player
        VideoPlayer = BasicVideoPlayer;
    } else {
        VideoPlayer = ShakaVideoPlayer;
    }

    useEffect(() => {
        if (playerState.play && playInline === false) {
            onPlayChange(false);
            onPlayChange(true);
            setPlayInline(true);
        }
    }, [playInline]);

    useEffect(() => {
        if (playerState.loading) {
            if (lastPosBeforeLoading.current === undefined) {
                lastPosBeforeLoading.current = playerState.currentTime;
            } else if (lastPosBeforeLoading.current !== playerState.currentTime) {
                handleEndBuffering();
                lastPosBeforeLoading.current = undefined;
            }
        } else if (lastPosBeforeLoading.current !== undefined) {
            lastPosBeforeLoading.current = undefined;
        }
    }, [playerState.currentTime, playerState.loading])

    return (
        <main
            role="main"
            ref={(ref) => (ref ? (mainElement = ref) : undefined)}
        >
            <PlayerLayout
                playerState={playerState}
                forceRender={forceRender}
                duration={playerState.duration}
                PlayerControls={PlayerControls}
                currentSubtitleIndex={state.currentSubtitleIndex}
                subtitles={state.subtitles}
                handleSubtitle={handleSubtitle}
                audios={state.audios}
                handleAudio={handleAudio}
                currentAudioIndex={state.currentAudioIndex}
                handlePlayPauseButton={handlePlayPauseButton}
                changeCurrentTime={changeCurrentTime}
                timeForward={timeForward}
                replay={replay}
                progression={getProgression()}
                fullscreen={fullscreen}
                handleFullscreen={handleFullscreen}
                mouseActivity={mouseActivity}
                handleMouseActivity={handleMouseActivity}
                handleMuted={handleMuted}
                handleVolume={handleVolume}
                currentVolume={state.currentVolume}
                onClickVideo={onClickVideo}
                onDoubleClickVideo={onDoubleClickVideo}
                playerTracksMode={props.playerTracksMode}
                overlay={props.overlay}
            >
                <div
                    style={{ width: videoContainerWidth }}
                    className={styles.horizontalVideoContainer}
                >
                    <VideoPlayer
                        ref={
                            playerRef as React.MutableRefObject<VideoPlayerHandle>
                        }
                        playInline={playInline}
                        video={video}
                        autoPlay={true}
                        onError={handlePlayerError}
                        onEnded={handleEnded}
                        onLoaded={handleLoaded}
                        onTimeUpdate={handleTimeUpdate}
                        onPlay={handlePlay}
                        onPause={handlePause}
                        onAudioTrackChange={handleAudioTrackChange}
                        onSubtitleTrackChange={handleSubtitleTrackChange}
                        onLoading={handleBuffering}
                    />
                </div>
            </PlayerLayout>
        </main>
    );
}
