import { VariantConfig } from "@app/variant-config";

export function buildVariantConfig(
    config: Partial<VariantConfig>
): VariantConfig {
    return {
        backButton: config?.backButton ?? false,
        jumpValue: config?.jumpValue ?? 10,
        cssVariables: config?.cssVariables ?? {
            "--theme-color": "#000000",
            "--main-text-color": "#ffffff",
            "--button-main-text-color": "#000000",
            "--button-main-background-color": "#ffffff",
            "--button-second-text-color": "#ffffff",
            "--button-second-background-color": "transparent",
            "--player-controls-progress-bar-cursor-color": "#ffffff",
            "--player-controls-progress-bar-color": "#ffffff",
            //"--player-controls-progress-bar-background-color": "#b2468f"
            "--player-controls-slider-color": "#ffffff"
        },
    };
}
