import { useState } from "react";

import { ReactComponent as ArrowDownIcon } from "@app/assets/icons/arrowDown.svg";

import style from "@app/assets/styles/ebook.module.scss";

interface Props {
    tableOfContents: { title: string; href: string }[];
    setMenuOpen: (value: boolean) => void;
    locator: any;
    setLocator: (href: string) => void;
}

export const BookMenu = (props: Props) => {
    const { tableOfContents, setMenuOpen, locator, setLocator } = props;

    const [isTocOpen, setTocOpen] = useState(false);
    // const [isBookmarkOpen, setBookmarkOpen] = useState(false);

    return (
        <div
            className={style.ebookMenuContainer}
            onClick={() => setMenuOpen(false)}
        >
            <div
                className={style.ebookMenu}
                onClick={(e) => e.stopPropagation()}
            >
                <p className={style.ebookMenuTitle}>Womba Reader</p>
                <button
                    className={style.ebookMenuEntry}
                    onClick={() => setTocOpen(!isTocOpen)}
                >
                    <p>{"Table of contents"}</p>
                    <ArrowDownIcon fill="black" height={20} width={20} />
                </button>
                {isTocOpen &&
                    tableOfContents.map((content, index) => {
                        return (
                            <button
                                key={index}
                                className={style.ebookMenuContent}
                                style={
                                    locator.href === content.href
                                        ? { backgroundColor: "#80808050", color: "black" }
                                        : {}
                                }
                                onClick={() => setLocator(content.href)}
                            >
                                <p>{content.title}</p>
                            </button>
                        );
                    })}
                {/* <button
                    className={style.ebookMenuEntry}
                    onClick={() => setBookmarkOpen(!isBookmarkOpen)}
                >
                    <p>{"Bookmarks"}</p>
                </button>
                {isBookmarkOpen && <p>No bookmark yet</p>} */}
            </div>
        </div>
    );
};
