import style from "@app/assets/styles/ebook.module.scss";
import { UserSettings } from "@d-i-t-a/reader/dist/types/model/user-settings/UserSettings";

interface Props {
    currentSettings: any;
    setSettings: (userSettings: Partial<UserSettings>) => void;
    setMenuOpen: (value: boolean) => void;
    setScroll: (value: boolean) => void;
    isScrolling: boolean;
}

export const BookSettings = (props: Props) => {
    const { setMenuOpen, setSettings, setScroll, isScrolling } = props;

    return (
        <div
            className={style.ebookMenuSettingsContainer}
            onClick={() => setMenuOpen(false)}
        >
            <div
                className={style.ebookMenuSettings}
                onClick={(e) => e.stopPropagation()}
            >
                <p className={style.ebookMenuTitle}>Settings</p>
                <div className={style.ebookMenuEntry}>
                    <button
                        className={style.dayButton}
                        onClick={() =>
                            setSettings({ appearance: "readium-day-on" })
                        }
                    >
                        Day
                    </button>
                    <button
                        className={style.sepiaButton}
                        onClick={() =>
                            setSettings({ appearance: "readium-sepia-on" })
                        }
                    >
                        Sepia
                    </button>
                    <button
                        className={style.nightButton}
                        onClick={() =>
                            setSettings({ appearance: "readium-night-on" })
                        }
                    >
                        Night
                    </button>
                </div>
                <p className={style.ebookMenuEntry}>Layout</p>
                <div className={style.ebookMenuEntry}>
                    <button
                        className={
                            isScrolling ? style.selectedOption : undefined
                        }
                        onClick={() => setScroll(true)}
                    >
                        Scroll
                    </button>
                    <button
                        className={
                            isScrolling ? undefined : style.selectedOption
                        }
                        onClick={() => setScroll(false)}
                    >
                        Paginated
                    </button>
                </div>
            </div>
        </div>
    );
};
