import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as PlayIcon } from "@app/assets/icons/play.svg";
import { ReactComponent as PauseIcon } from "@app/assets/icons/pause.svg";
import { ReactComponent as Volume } from "@app/assets/icons/volume.svg";
import { ReactComponent as VolumeDown } from "@app/assets/icons/volumeDown.svg";
import { ReactComponent as VolumeMute } from "@app/assets/icons/volumeMute.svg";
import { ReactComponent as ForwardIcon } from "@app/assets/icons/forward.svg";
import { ReactComponent as ReplayIcon } from "@app/assets/icons/replay.svg";
import { ReactComponent as SkipNext } from "@app/assets/icons/skipNext.svg";
import { ReactComponent as SkipPrevious } from "@app/assets/icons/skipPrevious.svg";

import style from "@app/assets/styles/audio-player.module.scss";

import { ProgressBar } from "../ProgressBar";

import { secondsToDuration } from "@app/utils/time";

import VolumeBar from "../video/VolumeBar";

import { DrmConfig, DrmExtra, MediaDistributionUrl } from "@app/models/player";
import { Oval } from "react-loader-spinner";

import { commonConfig as variant } from "@app/variant/variant-default";

import { ShakaAudioPlayer } from "./ShakaAudioPlayer";
import { BasicAudioPlayer } from "./BasicAudioPlayer";

import { AudioPlayerHandle, LoadedMetadataAudio } from "./audio-player";
interface State {
    muted: boolean;
    currentVolume: number;
    previousVolume: number;
}

export interface PlayerState {
    play: boolean;
    ended: boolean;
    duration: number;
    loading: boolean;
    hiding: boolean;
    onTimeChanging: boolean;
    onTimeChangingLeftOutOfBound: boolean;
    onTimeChangingRightOutOfBound: boolean;
    onFastForward: boolean;
    totalMoveTime: number;
    currentTime: number;
    showDoubleChevron: boolean;
    lastFastMove: number;
    sessionDuration: number;
    sessionLastStartDate: any;
}

const playerInfoState: State = {
    muted: false,
    currentVolume: 100,
    previousVolume: 100,
};

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

type Props = {
    mediaDistributionUrl: MediaDistributionUrl;
    drmConfig?: DrmConfig;
    drmExtra?: DrmExtra;
    viewMode: string;
    controls: string;
};

export const AudioPlayer = (props: Props) => {
    const { mediaDistributionUrl, viewMode, controls } = props;

    const [, setUpdatedAt] = useState(0);
    const [state, setState] = useState<State>(playerInfoState);
    const [volumeFocus, setVolumeFocus] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    let audioRef = useRef<any>(null);
    const playerState = useRef<PlayerState>({
        play: false,
        ended: false,
        loading: false,
        duration: 0,
        hiding: false,
        onTimeChanging: false,
        onFastForward: false,
        onTimeChangingLeftOutOfBound: false,
        onTimeChangingRightOutOfBound: false,
        totalMoveTime: 0,
        currentTime: 0,
        showDoubleChevron: false,
        lastFastMove: 0,
        sessionDuration: 0,
        sessionLastStartDate: 0,
    }).current;

    let bufferingTimer: any;
    const jumpValue = variant.jumpValue;
    const lastPeriodicTime = useRef<number | undefined>();

    const inIframe = useMemo(() => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }, []);

    const useBasicPlayer = useMemo(() => {
        return mediaDistributionUrl.contentType.startsWith("audio/");
    }, [mediaDistributionUrl.contentType]);

    const sendPostMessage = useCallback(
        (type: string, data?: any) => {
            if (inIframe) {
                window.parent.postMessage(
                    {
                        type,
                        data,
                    },
                    "*"
                );
            }
        },
        [inIframe]
    );

    const forceRender = useCallback(() => {
        const now = new Date();
        setUpdatedAt(now.getTime());
    }, []);

    const handlePlayerError = useCallback((event: any) => {
        console.error("Player error", event);
    }, []);

    const handleLoaded = useCallback((loadedMetadata: LoadedMetadataAudio) => {
        const duration = loadedMetadata.duration;

        playerState.duration = duration;
        sendPostMessage("ready");
        forceRender();
    }, []);

    const handlePlayPauseButton = () => {
        if (playerState.play) {
            sendPostMessage("paused", playerState.currentTime);
            audioRef.current.pause();
            playerState.play = false;
        } else {
            sendPostMessage("playing", playerState.currentTime);
            audioRef.current.play();
            playerState.play = true;
        }
        forceRender();
    };

    const handleMuted = useCallback(() => {
        const newValue = !state.muted;
        sendPostMessage("volume-changed", newValue ? 0 : state.previousVolume);
        audioRef?.current?.muted(newValue);
        const currentVolume = state.muted ? state.previousVolume : 0;
        audioRef?.current?.volume(currentVolume / 100);
        setState((prevState) => {
            return {
                ...prevState,
                muted: newValue,
                previousVolume: state.currentVolume,
                currentVolume: currentVolume,
            };
        });
    }, [state]);

    const handleVolume = useCallback(
        (value: number) => {
            if (audioRef.current) {
                sendPostMessage("volume-changed", value);
                audioRef.current.volume(value / 100);
                const muted = value ? false : true;
                audioRef.current.muted(muted);
                setState((prevState) => {
                    return {
                        ...prevState,
                        muted: muted,
                        previousVolume: value ? state.previousVolume : 50,
                        currentVolume: value,
                    };
                });
            }
        },
        [state]
    );

    const changeCurrentTime = (time: number) => {
        audioRef.current.seek((time * playerState.duration) / 100);
    };

    const seek = (time: number) => {
        audioRef.current.seek(time);
    };

    const timeForward = (value: number) => {
        const time = playerState.currentTime;
        if (time + value > playerState.duration) {
            playerState.onTimeChangingRightOutOfBound = true;
        } else {
            playerState.onTimeChangingRightOutOfBound = false;
        }
        if (!playerState.onTimeChangingRightOutOfBound) {
            audioRef.current.seek(time + value);
        }
    };
    const replay = (value: number) => {
        const time = playerState.currentTime;
        if (time - value < 0) {
            playerState.onTimeChangingLeftOutOfBound = true;
        } else {
            playerState.onTimeChangingLeftOutOfBound = false;
        }
        if (!playerState.onTimeChangingLeftOutOfBound) {
            audioRef.current.seek(time - value);
        }
    };

    const handleTimeUpdate = useCallback((timecode: number) => {
        forceRender();
        playerState.currentTime = timecode;
    }, []);

    const handleBuffering = () => {
        clearTimeout(bufferingTimer);
        playerState.loading = true;
        bufferingTimer = setTimeout(() => {
            playerState.loading = false;
        }, 100);
    };

    const handlePrevious = () => {
        sendPostMessage("previous-track");
    };

    const handleNext = () => {
        sendPostMessage("next-track");
    };

    useEffect(() => {
        if (!lastPeriodicTime.current || (playerState.currentTime > (lastPeriodicTime.current + 5)) || (playerState.currentTime < (lastPeriodicTime.current - 5))) {
            sendPosition("periodic-position");
            lastPeriodicTime.current = playerState.currentTime;
        }
    }, [playerState.currentTime]);

    const handleKey = (e: any) => {
        let intercepted = false;

        switch (e.code) {
            case "ArrowLeft":
                replay(variant.jumpValue);
                intercepted = true;
                break;
            case "ArrowRight":
                timeForward(variant.jumpValue);
                intercepted = true;
                break;
            case "ArrowUp":
                handleVolume(
                    state.currentVolume > 95 ? 100 : state.currentVolume + 5
                );
                intercepted = true;
                break;
            case "ArrowDown":
                handleVolume(
                    state.currentVolume < 5 ? 0 : state.currentVolume - 5
                );
                intercepted = true;
                break;
            case "Space":
                handlePlayPauseButton();
                intercepted = true;
                break;
            // M key
            case "Semicolon":
                handleMuted();
                forceRender();
                intercepted = true;
                break;
        }

        if (intercepted) {
            // Cancel bubbles
            e.preventDefault();
            return false;
        }
    };

    const sendPosition = (eventName: string) => {
        sendPostMessage(eventName, playerState.currentTime);
    };

    const handleWebEvents = useCallback((event: MessageEvent<any>) => {
        switch (event.data.type) {
            case "play":
                audioRef.current.play();
                playerState.play = true;
                //console.log("here");
                sendPostMessage("playing", playerState.currentTime);
                break;
            case "pause":
                audioRef.current.pause();
                playerState.play = false;
                sendPostMessage("paused", playerState.currentTime);
                break;
            case "volume-change":
                handleVolume(event.data.data);
                break;
            case "seek":
                seek(event.data.data);
                break;
            case "get-position":
                sendPosition("position");
                break;
            default:
                console.log("Event type ", event.data.type, " is unknow");
                break;
        }
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        if (inIframe) {
            window.addEventListener("message", (event) =>
                handleWebEvents(event)
            );
        }
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("message", (event) =>
                handleWebEvents(event)
            );
        };
    }, []);

    useEffect(() => {
        window.addEventListener("keyup", handleKey);
        return () => {
            window.removeEventListener("keyup", handleKey);
        };
    }, [state]);

    const audio = {
        url: mediaDistributionUrl.url,
        drmConfig: props.drmConfig,
        drmExtra: props.drmExtra,
    };

    const iconSize =
        windowDimensions.width < 700
            ? "10vw"
            : windowDimensions.width > 1500
            ? "3vw"
            : "6vw";

    let AudioPlayer = BasicAudioPlayer;

    if (useBasicPlayer) {
        AudioPlayer = BasicAudioPlayer;
    } else {
        AudioPlayer = ShakaAudioPlayer;
    }

    return viewMode === "mini" ? (
        <div className={style.audioPlayerMini}>
            <div className={style.audioControls}>
                {controls.includes("previous") && (
                    <button onClick={handlePrevious}>
                        <SkipPrevious
                            width={iconSize}
                            height={iconSize}
                            fill={
                                variant.cssVariables[
                                    "--button-second-text-color"
                                ]
                            }
                        />
                    </button>
                )}
                {playerState.loading ? (
                    <Oval
                        height={iconSize}
                        width={iconSize}
                        color={
                            variant.cssVariables["--button-second-text-color"]
                        }
                        wrapperClass={style.loader}
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#000000"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                    />
                ) : (
                    <button
                        onClick={handlePlayPauseButton}
                        className={style.playButton}
                    >
                        {!playerState.play ? (
                            <PlayIcon
                                width={iconSize}
                                height={iconSize}
                                fill={
                                    variant.cssVariables[
                                        "--button-main-text-color"
                                    ]
                                }
                            />
                        ) : (
                            <PauseIcon
                                width={iconSize}
                                height={iconSize}
                                fill={
                                    variant.cssVariables[
                                        "--button-main-text-color"
                                    ]
                                }
                            />
                        )}
                    </button>
                )}
                {controls.includes("next") && (
                    <button onClick={handleNext}>
                        <SkipNext
                            width={iconSize}
                            height={iconSize}
                            fill={
                                variant.cssVariables[
                                    "--button-second-text-color"
                                ]
                            }
                        />
                    </button>
                )}
            </div>
            <ProgressBar
                playerState={playerState}
                duration={playerState.duration}
                changeCurrentTime={changeCurrentTime}
                style={style}
            />
            <AudioPlayer
                ref={audioRef as React.MutableRefObject<AudioPlayerHandle>}
                audio={audio}
                autoPlay={true}
                onError={handlePlayerError}
                onEnded={() => sendPostMessage("playback-finished")}
                onLoaded={handleLoaded}
                onTimeUpdate={handleTimeUpdate}
                onLoading={handleBuffering}
            />
        </div>
    ) : (
        <div className={style.audioPlayer}>
            <div className={style.audioLayout}>
                <p className={style.audioTitle}>Womba player</p>
                <ProgressBar
                    playerState={playerState}
                    duration={playerState.duration}
                    changeCurrentTime={changeCurrentTime}
                    style={style}
                />
                <div className={style.audioControls}>
                    {controls.includes("previous") && (
                        <button onClick={handlePrevious}>
                            <SkipPrevious
                                width={iconSize}
                                height={iconSize}
                                fill={
                                    variant.cssVariables[
                                        "--button-second-text-color"
                                    ]
                                }
                            />
                        </button>
                    )}
                    {controls.includes("replay") && (
                        <button onClick={() => replay(jumpValue)}>
                            <p className={style.jumpTimeText}>{jumpValue}</p>
                            <ReplayIcon
                                fill={
                                    variant.cssVariables[
                                        "--button-second-text-color"
                                    ]
                                }
                                width={iconSize}
                                height={iconSize}
                            />
                        </button>
                    )}
                    {playerState.loading ? (
                        <Oval
                            height={iconSize}
                            width={iconSize}
                            color={
                                variant.cssVariables[
                                    "--button-second-text-color"
                                ]
                            }
                            wrapperClass={style.loader}
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#000000"
                            strokeWidth={5}
                            strokeWidthSecondary={5}
                        />
                    ) : (
                        <button
                            onClick={handlePlayPauseButton}
                            className={style.playButton}
                        >
                            {!playerState.play ? (
                                <PlayIcon
                                    width={iconSize}
                                    height={iconSize}
                                    fill={
                                        variant.cssVariables[
                                            "--button-main-text-color"
                                        ]
                                    }
                                />
                            ) : (
                                <PauseIcon
                                    width={iconSize}
                                    height={iconSize}
                                    fill={
                                        variant.cssVariables[
                                            "--button-main-text-color"
                                        ]
                                    }
                                />
                            )}
                        </button>
                    )}
                    {controls.includes("forward") && <button onClick={() => timeForward(jumpValue)}>
                        <p className={style.jumpTimeText}>{jumpValue}</p>
                        <ForwardIcon
                            fill={
                                variant.cssVariables[
                                    "--button-second-text-color"
                                ]
                            }
                            width={iconSize}
                            height={iconSize}
                        />
                    </button>}
                    {controls.includes("next") && (
                        <button onClick={handleNext}>
                            <SkipNext
                                width={iconSize}
                                height={iconSize}
                                fill={
                                    variant.cssVariables[
                                        "--button-second-text-color"
                                    ]
                                }
                            />
                        </button>
                    )}
                    <button
                        onClick={handleMuted}
                        className={style.volumeButton}
                        onMouseEnter={() => setVolumeFocus(true)}
                        onMouseLeave={() => setVolumeFocus(false)}
                    >
                        {!state.currentVolume ? (
                            <VolumeMute
                                fill={
                                    variant.cssVariables[
                                        "--button-second-text-color"
                                    ]
                                }
                                width={iconSize}
                                height={iconSize}
                            />
                        ) : state.currentVolume < 50 ? (
                            <VolumeDown
                                fill={
                                    variant.cssVariables[
                                        "--button-second-text-color"
                                    ]
                                }
                                width={iconSize}
                                height={iconSize}
                            />
                        ) : (
                            <Volume
                                fill={
                                    variant.cssVariables[
                                        "--button-second-text-color"
                                    ]
                                }
                                width={iconSize}
                                height={iconSize}
                            />
                        )}
                    </button>
                    <VolumeBar
                        currentVolume={state.currentVolume}
                        handleVolume={handleVolume}
                        volumeOnFocus={setVolumeFocus}
                        volumeFocus={volumeFocus}
                        style={style}
                    />
                    <p className={style.duration}>
                        {secondsToDuration(
                            playerState.currentTime,
                            "hh:mm:ss"
                        ) +
                            "/" +
                            secondsToDuration(playerState.duration, "hh:mm:ss")}
                    </p>
                </div>
                <AudioPlayer
                    ref={audioRef as React.MutableRefObject<AudioPlayerHandle>}
                    audio={audio}
                    autoPlay={true}
                    onError={handlePlayerError}
                    onEnded={() => sendPostMessage("playback-finished")}
                    onLoaded={handleLoaded}
                    onTimeUpdate={handleTimeUpdate}
                    onLoading={handleBuffering}
                />
            </div>
        </div>
    );
};
