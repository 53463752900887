import ISO6391 from "iso-639-1";

export function getNativeName(code: string): string {
    const nativeName = ISO6391.getNativeName(code.toLowerCase().substring(0, 2));
    if (nativeName == null || nativeName === "") {
        return code;
    }
    return nativeName;
}

export function getName(code: string): string {
    const name = ISO6391.getName(code.toLowerCase().substring(0, 2));
    if (name == null || name === "") {
        return code;
    }
    return name;
}
