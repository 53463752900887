import { DrmConfig, DrmExtra } from "@app/models/player";
import { SyntheticEvent } from "react";

export interface LoadedMetadataAudio {
    duration: number; // Float in seconds
}

export interface AudioPlayerProps {
    audio: Audio;
    autoPlay?: boolean;
    onLoaded?: (loadedMetadata: LoadedMetadataAudio) => void;
    onLoading?: () => void; // buffering
    onEnded?: () => void;
    onTimeUpdate?: (timecode: number) => void;
    onError?: (error: unknown) => void;
}

export interface AudioPlayerHandle {
    play: () => void;
    pause: () => void;
    seek: (timecode: number) => void; // timecode in seconds (float)
    muted: (muted: boolean) => void;
    volume: (volume: number) => void;
}

export function extendAudioProps(props: AudioPlayerProps) {
    return {
        onEnded: () => {
            props?.onEnded?.();
        },
        onTimeUpdate: (event: SyntheticEvent<HTMLVideoElement, Event>) => {
            props?.onTimeUpdate?.(event.currentTarget.currentTime);
        },
    };
}

export interface Audio {
    url: string;
    drmConfig?: DrmConfig;
    drmExtra?: DrmExtra;
}
