import React, { useMemo, useCallback, useEffect, useState, useRef } from "react";
import D2Reader from "@d-i-t-a/reader";
import { Oval } from "react-loader-spinner";

import { ReactComponent as ArrowLeft } from "@app/assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "@app/assets/icons/arrowRight.svg";
import { ReactComponent as Menu } from "@app/assets/icons/menu.svg";
import { ReactComponent as BookmarkAdd } from "@app/assets/icons/bookmarkAdd.svg";
import { ReactComponent as BookmarkRemove } from "@app/assets/icons/bookmarkRemove.svg";
import { ReactComponent as Bookmarks } from "@app/assets/icons/bookmarks.svg";
import { ReactComponent as Settings } from "@app/assets/icons/settings.svg";
import { ReactComponent as Search } from "@app/assets/icons/search.svg";

import "@d-i-t-a/reader/dist/reader.css";

import style from "@app/assets/styles/ebook.module.scss";
import { BookMenu } from "./BookMenu";
import { BookSettings } from "./BookSettings";
import { BookmarksModal } from "./BookmarksModal";
import { UserSettings } from "@d-i-t-a/reader/dist/types/model/user-settings/UserSettings";
import { MediaDistributionUrl } from "@app/models/player";
import { Bookmark } from "@d-i-t-a/reader/dist/types/model/Locator";

type Props = {
    mediaDistributionUrl: MediaDistributionUrl;
};

export const BookPlayer = (props: Props) => {
    const { mediaDistributionUrl } = props;
    const [reader, setReader] = React.useState<D2Reader | null>(null);
    const [isReaderNodeReady, setReaderNodeReady] = useState<boolean>(false);
    const [_state, setState] = React.useState(0);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSettingsOpen, setSettingsOpen] = useState(false);
    const [isBookmarksOpen, setBookmarksOpen] = useState(false);

    const bookmarksRef = useRef(reader?.bookmarks);

    const injectables = useMemo(() => {
        const baseUrl = window.location.origin;
        return [
            {
                type: "style",
                url: baseUrl + "/readium-css/before.css",
                r2before: true,
            },
            {
                type: "style",
                url: baseUrl + "/readium-css/before.css",
                r2default: true,
            },
            {
                type: "style",
                url: baseUrl + "/readium-css/after.css",
                r2after: true,
            },
        ];
    }, []);

    const inIframe = useMemo(() => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }, []);

    const sendPostMessage = useCallback((type: string, data?: any) => {
        if (inIframe) {
            window.parent.postMessage(
                {
                    type,
                    data
                },
                "*"
            );
        }
    }, [inIframe]);

    const didUpdate = useCallback(() => {
        setState((state) => state + 1);
    }, []);

    const setLayout = useCallback(
        (value: boolean) => {
            reader?.scroll(value);
            didUpdate();
        },
        [reader]
    );

    // const paginate = useCallback(() => {
    //     reader?.scroll(false);
    //     didUpdate();
    // }, [reader]);

    const bookmark = useCallback(() => {
        reader?.saveBookmark();
        didUpdate();
    }, [reader]);

    const setSettings = useCallback(
        (userSettings: Partial<UserSettings>) => {
            reader?.applyUserSettings(userSettings);
            didUpdate();
        },
        [reader]
    );

    const setLocator = (href: string, progression?: number) => {
        reader?.goTo({ href, locations: { progression: progression ?? 0 } });
        sendPostMessage("bookmark-loaded", { href, locations: { progression: progression ?? 0 } });
        didUpdate();
    };

    const deleteBookmark = (bookmark: Bookmark) => {
        reader?.deleteBookmark(bookmark);
        didUpdate();
    };

    // Used to initialize book player
    const initReaderRef = useCallback((node: any) => {
        if (node == null) {
            return;
        }

        setReaderNodeReady(true);
    }, []);

    const sendBookmarks = useCallback(() => {
        sendPostMessage("bookmark-list", bookmarksRef.current);
    }, [reader?.bookmarks]);

    const handleWebEvents = useCallback((event: MessageEvent<any>) => {
        switch (event.data.type) {
            case "bookmark-list":
                sendBookmarks();
                break;
            case "bookmark-load":
                setLocator(event.data.data);
                break;
            default:
                console.log("Event type ", event.data.type, " is unknow");
                break;
        }
    }, []);

    useEffect(() => {
        if (!isReaderNodeReady) return;

        const url = new URL(mediaDistributionUrl.url);
        D2Reader.load({
            url,
            injectables,
            injectablesFixed: [],
            rights: {
                enableBookmarks: true,
            },
        }).then(setReader);

        return () => {
            // Unmount
        };
    }, [isReaderNodeReady]);

    useEffect(() => {
        if (inIframe) {
            window.addEventListener("message", (event) => handleWebEvents(event));
        }
        return () => {
            window.removeEventListener("message", (event) => handleWebEvents(event));
        };
    }, []);

    useEffect(() => {
        if (bookmarksRef.current !== reader?.bookmarks) {
            bookmarksRef.current = reader?.bookmarks;
        }
    }, [reader?.bookmarks])

    const isScrolling = reader?.currentSettings.verticalScroll ?? false;
    return (
        <div className={style.ebookReader}>
            {!reader ? (
                <div className={style.loader}>
                    <Oval
                        height={120}
                        width={120}
                        color="#000000"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#000000"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                    />
                </div>
            ) : (
                <div className={style.topContainer}>
                    <button onClick={() => setMenuOpen(true)}>
                        <Menu fill="black" width={30} height={30} />
                    </button>
                    <p>Womba Reader</p>
                    <div className={style.topContainerRight}>
                        <button onClick={bookmark}>
                            {reader?.bookmarks.find(
                                (bookmark: { id: any }) =>
                                    bookmark.id === reader.currentLocator.id
                            ) ? (
                                <BookmarkRemove
                                    fill="black"
                                    width={30}
                                    height={30}
                                />
                            ) : (
                                <BookmarkAdd
                                    fill="black"
                                    width={30}
                                    height={30}
                                />
                            )}
                        </button>
                        <button onClick={() => setBookmarksOpen(true)}>
                            <Bookmarks fill="black" width={30} height={30} />
                        </button>
                        <button onClick={() => setSettingsOpen(true)}>
                            <Settings fill="black" width={30} height={30} />
                        </button>
                        {/* <button>
                            <Search fill="black" width={30} height={30} />
                        </button> */}
                    </div>
                </div>
            )}
            <div
                ref={initReaderRef}
                id="D2Reader-Container"
                className={style.readerContainer}
            >
                <main
                    tabIndex={-1}
                    id="iframe-wrapper"
                    style={{
                        height: "calc(100vh - 8vh)",
                        width: "100%",
                        position: "fixed",
                        bottom: "0px",
                    }}
                >
                    <div id="reader-loading" className="loading"></div>
                    <div id="reader-error" className="error"></div>
                </main>
            </div>
            {reader && (
                <div className={style.bottomContainer}>
                    <button onClick={reader.previousPage}>
                        <ArrowLeft fill="black" width={30} height={30} />
                    </button>
                    <button onClick={reader.nextPage}>
                        <ArrowRight fill="black" width={30} height={30} />
                    </button>
                </div>
            )}
            {isMenuOpen && (
                <BookMenu
                    setMenuOpen={setMenuOpen}
                    tableOfContents={reader?.tableOfContents}
                    locator={reader?.currentLocator}
                    setLocator={setLocator}
                />
            )}
            {isSettingsOpen && (
                <BookSettings
                    setMenuOpen={setSettingsOpen}
                    currentSettings={reader?.currentSettings}
                    setSettings={setSettings}
                    isScrolling={isScrolling}
                    setScroll={setLayout}
                />
            )}
            {isBookmarksOpen && (
                <BookmarksModal
                    setModalOpen={setBookmarksOpen}
                    bookmarks={reader?.bookmarks}
                    setLocation={setLocator}
                    deleteBookmark={deleteBookmark}
                />
            )}
        </div>
    );
};
